import { defineStore } from "pinia"
import { computed, ref } from "vue"
import { useI18n } from "vue-i18n"

export type DialogOptions = {
  header?: string
  message: string
  icon?: string
  accept?: () => void
  reject?: () => void
  action?: () => void
  actionIcon?: string
  actionLabel?: string
  acceptLabel?: string
  rejectLabel?: string
  acceptClass?: string
  rejectClass?: string
  actionClass?: string
}

export const useDialogStore = defineStore("dialog", () => {
  const { t: $t } = useI18n()

  const state = ref<DialogOptions>()
  const currentOptions = computed(() => state.value) // make options immutable from outside the store
  const loading = ref(false)
  const defaultOptions: DialogOptions = {
    header: "",
    message: "",
    icon: "pi-exclamation-triangle",
  }

  function set<T extends DialogOptions>(options: string | T, defaultOptions: T) {
    if (typeof options === "string") {
      state.value = {
        ...defaultOptions,
        message: options,
      }
    } else {
      state.value = {
        ...defaultOptions,
        ...options,
      }
    }
  }
  function setLoading(value: boolean) {
    loading.value = value
  }

  function confirm(options: string | DialogOptions) {
    set(options, {
      ...defaultOptions,
      header: "Hinweis",
      acceptLabel: "Ja",
      rejectLabel: "Nein",
    })
  }

  function alert(options: string | DialogOptions) {
    set(options, {
      ...defaultOptions,
      header: "Fehler",
      acceptLabel: "OK",
    })
  }
  function info(options: DialogOptions) {
    set(options, {
      ...defaultOptions,
      header: "Information",
      acceptLabel: "OK",
    })
  }
  function close() {
    state.value = undefined
  }

  function confirmDiscard(message: string, options: Partial<DialogOptions> = {}) {
    return new Promise<void>((resolve) => {
      confirm({
        header: $t("messages.confirmation"),
        acceptClass: "p-button-danger",
        accept: () => {
          close()
          resolve()
        },
        rejectLabel: $t("buttons.back"),
        acceptLabel: $t("buttons.discard"),
        ...options,
        message,
      })
    })
  }

  return {
    currentOptions,
    defaultOptions,
    confirm,
    alert,
    close,
    setLoading,
    loading,
    info,
    confirmDiscard,
  }
})

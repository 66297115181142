import {
  type ApolloCache,
  type DefaultContext,
  type MutationUpdaterFunction,
  type OperationVariables,
} from "@apollo/client"
import { type Reference, type Modifier } from "@apollo/client/cache"
import { type Modifiers } from "@apollo/client/cache/core/types/common"
import { type Ref } from "vue"

import { type BaseEntity } from "@/types"
import { splitLongId } from "@/utils/entity"

type IdPair = {
  id: string
  foreignId: string
}

type UseCacheUpdate = {
  cache: ApolloCache<unknown>
  id: string
  reducer: Modifier<ReadonlyArray<Reference>>
}

type Props<
  TEntity extends BaseEntity,
  TForeignEntity extends BaseEntity,
  TForeignField extends keyof TEntity,
  TCreateVariables extends OperationVariables,
  TRemoveVariables extends OperationVariables,
> = {
  typename: string
  foreignField: TForeignField
  foreignSource: Ref<TForeignEntity[]>
  mapEntity: (entity: TForeignEntity) => TForeignEntity
  mapCreationIds: (variables: TCreateVariables) => IdPair
  mapRemovalIds: (variables: TRemoveVariables) => IdPair[]
}

export function useManyToManyCacheUpdate<
  TEntity extends BaseEntity,
  TForeignEntity extends BaseEntity,
  TForeignField extends keyof TEntity,
  TCreateResult,
  TRemoveResult,
  TCreateVariables extends OperationVariables,
  TRemoveVariables extends OperationVariables,
>({
  typename,
  foreignField,
  foreignSource,
  mapEntity,
  mapCreationIds,
  mapRemovalIds,
}: Props<TEntity, TForeignEntity, TForeignField, TCreateVariables, TRemoveVariables>) {
  const updateOnCreate: MutationUpdaterFunction<
    TCreateResult,
    TCreateVariables,
    DefaultContext,
    ApolloCache<unknown>
  > = (cache, _, options) => {
    const { id, foreignId } = mapCreationIds(options.variables!)

    useCacheUpdate({
      cache,
      id,
      reducer: ((existingRefs, { toReference }) => {
        const rawEntity = foreignSource.value.find((entity) => entity.id === foreignId)

        const mappedEntity = rawEntity ? mapEntity(rawEntity) : undefined
        const createdRef = mappedEntity ? toReference(mappedEntity) : undefined

        if (!createdRef) return existingRefs

        return [...existingRefs, createdRef]
      }) as Modifier<ReadonlyArray<Reference>>,
    })
  }

  const updateOnRemove: MutationUpdaterFunction<
    TRemoveResult,
    TRemoveVariables,
    DefaultContext,
    ApolloCache<unknown>
  > = (cache, _, options) => {
    for (const { id, foreignId } of mapRemovalIds(options.variables!)) {
      useCacheUpdate({
        cache,
        id,
        reducer: (existingRefs, { readField }) =>
          (existingRefs ?? []).filter((ref) => {
            const id = readField("id", ref)

            if (typeof id !== "string") return false

            return splitLongId(id)[3] !== foreignId
          }),
      })
    }
  }

  function useCacheUpdate({ cache, id, reducer }: UseCacheUpdate) {
    const modify = ((existingRefs, details) => {
      try {
        if (!Array.isArray(existingRefs)) {
          // TODO: error
          return
        }

        return reducer(existingRefs, details)
      } catch (error) {
        console.error("Cache update error:", error)
        return existingRefs
      }
    }) as Modifier<ReadonlyArray<Reference>>

    cache.modify<TEntity>({
      id: cache.identify({
        __typename: typename,
        id,
      }),
      // TODO: fix type some time
      fields: {
        [foreignField]: modify,
      } as unknown as Modifiers<TEntity>,
    })
  }

  return {
    updateOnCreate,
    updateOnRemove,
  }
}

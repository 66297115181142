export const emptyId = "0".repeat(24)

// https://digitale-verwaltung.atlassian.net/wiki/spaces/adebisKITA/pages/882704435/Entities+Ownership
// e.g. const [cid, oid, iid, id] = splitLongId(longId)
export const splitLongId = (longId: string) => [
  longId.slice(0, 24),
  longId.slice(24, 48),
  longId.slice(48, 72),
  longId.slice(72, 96),
]
export const joinLongId = (a?: string, b?: string, c?: string, d?: string) => {
  return [a || emptyId, b || emptyId, c || emptyId, d || emptyId].join("")
}

export type IDParts = {
  cid: string
  oid?: string
  iid?: string
  id: string
}

export const objectId = (longId: string) => splitLongId(longId)[3]
export const adminIds = (longId: string) =>
  splitLongId(longId)[0] + splitLongId(longId)[1] + splitLongId(longId)[2]

export type StrictEntityId = String

// # # # # # # # # # # # # # # # # # #
// # Automatically generated file!   #
// # Update via: yarn apollo:update  #
// # # # # # # # # # # # # # # # # # #

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Bic: { input: any; output: any; }
  Cid: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Iban: { input: any; output: any; }
  Iid: { input: any; output: any; }
  LongEntityId: { input: any; output: any; }
  NaiveDate: { input: any; output: any; }
  NaiveTime: { input: any; output: any; }
  ObjectId: { input: any; output: any; }
  Oid: { input: any; output: any; }
  RoleId: { input: any; output: any; }
  StrictEntityId: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Uid: { input: any; output: any; }
};

export type AccessInput = {
  customer?: InputMaybe<CustomerLevel>;
  customerUnit?: InputMaybe<CustomerUnitLevel>;
  institution?: InputMaybe<InstitutionLevel>;
  organization?: InputMaybe<OrganizationLevel>;
  organizationUnit?: InputMaybe<OrganizationUnitLevel>;
};

export enum AccessLevel {
  Customer = 'CUSTOMER',
  Institution = 'INSTITUTION',
  Organization = 'ORGANIZATION',
  OrganizationUnit = 'ORGANIZATION_UNIT'
}

/** An owned version of an RcObject, used to send to the frontend to add data such as `id`, `organization`, `institution` and `linked_to`. */
export type Address = {
  __typename?: 'Address';
  accountHolder?: Maybe<Scalars['String']['output']>;
  accountType?: Maybe<Scalars['String']['output']>;
  bankInstitution?: Maybe<Scalars['String']['output']>;
  bic?: Maybe<Scalars['Bic']['output']>;
  birthdate?: Maybe<Scalars['NaiveDate']['output']>;
  /** Get the associated [`Categories`](Category). */
  categories?: Maybe<Array<AddressCategory>>;
  city?: Maybe<Scalars['String']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  country?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  doctor?: Maybe<Scalars['Boolean']['output']>;
  employer?: Maybe<Scalars['Boolean']['output']>;
  externalAddress?: Maybe<Scalars['Boolean']['output']>;
  externalPayer?: Maybe<Scalars['Boolean']['output']>;
  facility?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['Iban']['output']>;
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  lastname?: Maybe<Scalars['String']['output']>;
  /** Get [Institutions](RestrictedInstitution) this object is linked to. */
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  otherAddress?: Maybe<Scalars['Boolean']['output']>;
  salutation?: Maybe<Scalars['String']['output']>;
  specialService?: Maybe<Scalars['Boolean']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  volunteer?: Maybe<Scalars['Boolean']['output']>;
  volunteerDescription?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AddressCategory = {
  __typename?: 'AddressCategory';
  addressId: Scalars['ObjectId']['output'];
  /**
   * Get the [`Category`] object.
   *
   * Requires rights to the `category` resource.
   */
  category?: Maybe<Category>;
  categoryId: Scalars['ObjectId']['output'];
  created: Modification;
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
};

export type AddressList = {
  __typename?: 'AddressList';
  items: Array<Address>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/**
 * A model for the two time columns in the booking time table.
 *
 * The `am` time span must come before the `pm` time span, if both are specified.
 */
export type AmPm = {
  __typename?: 'AmPm';
  am?: Maybe<TimeSpan>;
  pm?: Maybe<TimeSpan>;
};

/**
 * A model for the two time columns in the booking time table.
 *
 * The `am` time span must come before the `pm` time span, if both are specified.
 */
export type AmPmInput = {
  am?: InputMaybe<TimeSpanInput>;
  pm?: InputMaybe<TimeSpanInput>;
};

export type Analysis = {
  __typename?: 'Analysis';
  date: Scalars['NaiveDate']['output'];
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  mode: Scalars['String']['output'];
  n: Scalars['Int']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  type: Scalars['String']['output'];
};

export type AnalysisList = {
  __typename?: 'AnalysisList';
  items: Array<Analysis>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Appointment = {
  __typename?: 'Appointment';
  absence: Scalars['Boolean']['output'];
  /**
   * Get the associated [`Child`](Child).
   *
   * Requires rights to the `child` resource.
   */
  children?: Maybe<Array<Child>>;
  /**
   * Get the associated [`Employee`](Employee).
   *
   * Requires rights to the `employee` resource.
   */
  employees?: Maybe<Array<Employee>>;
  from: Scalars['DateTime']['output'];
  fullDay: Scalars['Boolean']['output'];
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  n: Scalars['Int']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  subject: Scalars['String']['output'];
  subtype?: Maybe<AppointmentType>;
  text?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['DateTime']['output']>;
  type: AppointmentType;
};

export type AppointmentChild = {
  __typename?: 'AppointmentChild';
  appointmentId: Scalars['ObjectId']['output'];
  childId: Scalars['ObjectId']['output'];
  created: Modification;
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
};

export type AppointmentChildAssignment = {
  appointmentChildId?: InputMaybe<AppointmentChildId>;
  childAppointmentId?: InputMaybe<ChildAppointmentId>;
};

export type AppointmentChildId = {
  appointmentId: Scalars['StrictEntityId']['input'];
  childId: Scalars['ObjectId']['input'];
};

export type AppointmentEmployee = {
  __typename?: 'AppointmentEmployee';
  appointmentId: Scalars['ObjectId']['output'];
  created: Modification;
  employeeId: Scalars['ObjectId']['output'];
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
};

export type AppointmentEmployeeAssignment = {
  appointmentEmployeeId?: InputMaybe<AppointmentEmployeeId>;
  employeeAppointmentId?: InputMaybe<EmployeeAppointmentId>;
};

export type AppointmentEmployeeId = {
  appointmentId: Scalars['StrictEntityId']['input'];
  employeeId: Scalars['ObjectId']['input'];
};

export type AppointmentList = {
  __typename?: 'AppointmentList';
  items: Array<Appointment>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type AppointmentQueryFilter = {
  childId?: InputMaybe<Scalars['StrictEntityId']['input']>;
  employeeId?: InputMaybe<Scalars['StrictEntityId']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  subtype?: InputMaybe<Array<AppointmentType>>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Array<AppointmentType>>;
};

export enum AppointmentType {
  /** Seminar day (Seminartag) */
  A = 'A',
  /** Employment ban (Beschäftigungsverbot) */
  B = 'B',
  /** Expert converence (Fachtagung) */
  C = 'C',
  /** Child Sick (Erkrankung Kind) */
  E = 'E',
  /** Advanced training (Fortbildung) */
  F = 'F',
  /** Abscence (Freistellung) */
  G = 'G',
  /** Sick (Krank) */
  K = 'K',
  /** Flex day (Gleittag) */
  L = 'L',
  /** Maternity leave (Mutterschutz) */
  M = 'M',
  /** Regeneration day (Regenerationstag) */
  R = 'R',
  /** Misc (Sonstiger Termin) */
  S = 'S',
  /** Leave (Urlaub) */
  U = 'U',
  /** Change day (Umwandlungstag) */
  W = 'W',
  /** Closing time for advanced team training (Schließzeit Teamfortbildung) */
  Y = 'Y',
  /** Closing time (Schließzeit) */
  Z = 'Z'
}

export type Bank = {
  __typename?: 'Bank';
  accountHolder?: Maybe<Scalars['String']['output']>;
  accountType?: Maybe<Scalars['String']['output']>;
  bankInstitution?: Maybe<Scalars['String']['output']>;
  bic?: Maybe<Scalars['Bic']['output']>;
  iban?: Maybe<Scalars['Iban']['output']>;
};

export type Birth = {
  __typename?: 'Birth';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['NaiveDate']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  saintsDay?: Maybe<Scalars['NaiveDate']['output']>;
};

export type BirthInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['NaiveDate']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  saintsDay?: InputMaybe<Scalars['NaiveDate']['input']>;
};

export type Booking = {
  __typename?: 'Booking';
  /** "Eingliederungshilfe" */
  assistedIntegration: Scalars['Boolean']['output'];
  childId: Scalars['ObjectId']['output'];
  /**
   * Get the associated [`Commune`](Commune).
   *
   * Requires rights to the `commune` resource.
   */
  commune?: Maybe<Commune>;
  /** Commune for financial support */
  communeId?: Maybe<Scalars['StrictEntityId']['output']>;
  from: Scalars['NaiveDate']['output'];
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  /** "Mittagsverpflegung durch die Einrichtung" */
  lunch: Scalars['Boolean']['output'];
  /** "Migration" */
  migration: Scalars['Boolean']['output'];
  n: Scalars['Int']['output'];
  /** "Krippenkind" */
  nurseryChild: Scalars['Boolean']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  /** "Vorkurs" */
  preCourse: Scalars['Boolean']['output'];
  /** "Vorschulkind" with corresponding status */
  preSchooler?: Maybe<Enrollment>;
  /** "Anmerkungen zur Buchung" */
  remarks?: Maybe<Scalars['String']['output']>;
  schoolData?: Maybe<SchoolData>;
  /** "Bereich" */
  section?: Maybe<Scalars['String']['output']>;
  /** "Platzsplitting" */
  slotSplitting: Scalars['Boolean']['output'];
  /** "Zuschuss zum Elternbeitrag für Vorschulkind" */
  subsidyForParentalFee: Scalars['Boolean']['output'];
  /**
   * Set the time factor always
   *
   * If the `time_factor_manually_set` is `false` and the [`TimeFactorData`] does not match the actual `times: BookingTimes`, the `times` field should be used as the source of truth for further calculation.
   */
  timeFactor: TimeFactorData;
  /** Flag if the user set the time factor themselves */
  timeFactorManuallySet: Scalars['Boolean']['output'];
  times: BookingTimes;
  to?: Maybe<Scalars['NaiveDate']['output']>;
  type: BookingType;
};

export type BookingList = {
  __typename?: 'BookingList';
  items: Array<Booking>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum BookingTimeCategory {
  Eight = 'EIGHT',
  Eleven = 'ELEVEN',
  Five = 'FIVE',
  Four = 'FOUR',
  Nine = 'NINE',
  One = 'ONE',
  Seven = 'SEVEN',
  Six = 'SIX',
  Ten = 'TEN',
  Three = 'THREE',
  Twelve = 'TWELVE',
  Two = 'TWO',
  Zero = 'ZERO'
}

/**
 * A model for the booking time table.
 *
 * To update a day in the booking times table, you specify the whole day you want to update, both `am` and `pm` field.
 * You cannot change only one time span without changing the other.
 * If we allowed for this, it would be hard to validate whether the new time spans are in order.
 * If you don't want to change a day, simply leave it out `{}` or set it to `null` like `{ "mo": null }`.
 * If you want to delete a day, specify it an leave out the fields `{"mo": { } }` or set them to `null` like `{ "mo": { "am": null, "pm": null } }`.
 * `{ "mo": { "am": null } }` is also allowed and deletes both time spans.
 */
export type BookingTimes = {
  __typename?: 'BookingTimes';
  comments?: Maybe<Scalars['String']['output']>;
  fr: AmPm;
  mo: AmPm;
  th: AmPm;
  tu: AmPm;
  we: AmPm;
};

/**
 * A model for the booking time table.
 *
 * To update a day in the booking times table, you specify the whole day you want to update, both `am` and `pm` field.
 * You cannot change only one time span without changing the other.
 * If we allowed for this, it would be hard to validate whether the new time spans are in order.
 * If you don't want to change a day, simply leave it out `{}` or set it to `null` like `{ "mo": null }`.
 * If you want to delete a day, specify it an leave out the fields `{"mo": { } }` or set them to `null` like `{ "mo": { "am": null, "pm": null } }`.
 * `{ "mo": { "am": null } }` is also allowed and deletes both time spans.
 */
export type BookingTimesInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  fr?: AmPmInput;
  mo?: AmPmInput;
  th?: AmPmInput;
  tu?: AmPmInput;
  we?: AmPmInput;
};

export enum BookingType {
  /** "Alternativbuchung-1" */
  Alternative_1 = 'ALTERNATIVE_1',
  /** "Alternativbuchung-2" */
  Alternative_2 = 'ALTERNATIVE_2',
  /** "Kurzzeitbuchung" */
  ShortTime = 'SHORT_TIME',
  /** "Standard-/Regelbuchung" */
  Standard = 'STANDARD',
  /** "Ferienbuchung-1" */
  Vacation_1 = 'VACATION_1',
  /** "Ferienbuchung-2" */
  Vacation_2 = 'VACATION_2',
  /** "Ferienbuchung-3" */
  Vacation_3 = 'VACATION_3'
}

export type ByInstitution = {
  __typename?: 'ByInstitution';
  at: Scalars['NaiveDate']['output'];
};

export type ByInstitutionInput = {
  at: Scalars['NaiveDate']['input'];
};

export type ByParents = {
  __typename?: 'ByParents';
  at: Scalars['NaiveDate']['output'];
};

export type ByParentsInput = {
  at: Scalars['NaiveDate']['input'];
};

/** An owned version of an RcObject, used to send to the frontend to add data such as `id`, `organization`, `institution` and `linked_to`. */
export type Category = {
  __typename?: 'Category';
  abbreviation?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  /** Get [Institutions](RestrictedInstitution) this object is linked to. */
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
};

export type CategoryList = {
  __typename?: 'CategoryList';
  items: Array<Category>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Characteristic = {
  __typename?: 'Characteristic';
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
};

export type CharacteristicChildId = {
  characteristicId: Scalars['StrictEntityId']['input'];
  childId: Scalars['ObjectId']['input'];
};

export type CharacteristicList = {
  __typename?: 'CharacteristicList';
  items: Array<Characteristic>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Child = {
  __typename?: 'Child';
  active?: Maybe<Scalars['Boolean']['output']>;
  address?: Maybe<SimpleAddress>;
  /** Get the associated [`Addresses`](Address). */
  addresses?: Maybe<Array<ChildAddress>>;
  allergies?: Maybe<Array<Scalars['String']['output']>>;
  allowedToGoHomeAlone: Scalars['Boolean']['output'];
  birth: Birth;
  /**
   * Get the associated [`Booking`](Booking)s.
   *
   * Requires rights to the `booking` resource.
   */
  bookings: Array<Booking>;
  /**
   * Get the associated [`Characteristic`](Characteristic).
   *
   * Requires rights to the `characteristic` resource.
   */
  characteristics?: Maybe<Array<Characteristic>>;
  childId?: Maybe<Scalars['Int']['output']>;
  collectorNotes?: Maybe<Scalars['String']['output']>;
  collectors?: Maybe<Array<Collector>>;
  /**
   * Get the associated [`Commune`](Commune).
   *
   * Requires rights to the `commune` resource.
   */
  commune?: Maybe<Commune>;
  communeId?: Maybe<Scalars['ObjectId']['output']>;
  contact?: Maybe<Contact>;
  /**
   * Get the associated [`Contracts`](Contract).
   *
   * Requires rights to the `Contract` resource.
   */
  contracts?: Maybe<Array<Contract>>;
  created: Modification;
  /** Get the associated [`CustodianChildren`](CustodianChild). */
  custodians: Array<CustodianChild>;
  disability?: Maybe<Disability>;
  emergencyMeasures?: Maybe<Scalars['String']['output']>;
  furtherSiblings?: Maybe<Scalars['String']['output']>;
  /** Get the associated [`Group`](GroupChild)s. */
  groups: Array<GroupChild>;
  healthInsurance?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  modified?: Maybe<Modification>;
  movement: Movement;
  n: Scalars['Int']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  person: Person;
  pictureId?: Maybe<Scalars['String']['output']>;
  previousInstitutions?: Maybe<Array<Scalars['String']['output']>>;
  remarks?: Maybe<Scalars['String']['output']>;
  siblings?: Maybe<Array<Sibling>>;
  symbol?: Maybe<Scalars['String']['output']>;
  vaccination?: Maybe<Vaccination>;
  visitingNotes?: Maybe<Scalars['String']['output']>;
};

export type ChildAddress = {
  __typename?: 'ChildAddress';
  /**
   * Get the [`Address`] object.
   *
   * Requires rights to the `address` resource.
   */
  address?: Maybe<Address>;
  addressId: Scalars['ObjectId']['output'];
  childId: Scalars['ObjectId']['output'];
  created: Modification;
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
};

export type ChildAppointmentId = {
  appointmentId: Scalars['ObjectId']['input'];
  childId: Scalars['StrictEntityId']['input'];
};

export type ChildCharacteristic = {
  __typename?: 'ChildCharacteristic';
  characteristicId: Scalars['ObjectId']['output'];
  childId: Scalars['ObjectId']['output'];
  created: Modification;
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
};

export type ChildCharacteristicAssignment = {
  characteristicChildId?: InputMaybe<CharacteristicChildId>;
  childCharacteristicId?: InputMaybe<ChildCharacteristicId>;
};

export type ChildCharacteristicId = {
  characteristicId: Scalars['ObjectId']['input'];
  childId: Scalars['StrictEntityId']['input'];
};

export type ChildList = {
  __typename?: 'ChildList';
  items: Array<Child>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Collector = {
  __typename?: 'Collector';
  address?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

/** An owned version of an RcObject, used to send to the frontend to add data such as `id`, `organization`, `institution` and `linked_to`. */
export type Commune = {
  __typename?: 'Commune';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  comments?: Maybe<Scalars['String']['output']>;
  communeKey: Scalars['String']['output'];
  contactPerson?: Maybe<ContactPerson>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  /** Get [Institutions](RestrictedInstitution) this object is linked to. */
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CommuneList = {
  __typename?: 'CommuneList';
  items: Array<Commune>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** If used as an input argument in GraphQL, exactly one of the fields must be provided. */
export type Confirmed = ByInstitution | ByParents;

/** If used as an input argument in GraphQL, exactly one of the fields must be provided. */
export type ConfirmedInput = {
  byInstitution?: InputMaybe<ByInstitutionInput>;
  byParents?: InputMaybe<ByParentsInput>;
};

export type Contact = {
  __typename?: 'Contact';
  email?: Maybe<Scalars['String']['output']>;
  letterSalutation?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phoneMobile?: Maybe<Scalars['String']['output']>;
  phoneWork?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type ContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  letterSalutation?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  phoneWork?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type ContactPerson = {
  __typename?: 'ContactPerson';
  address?: Maybe<SimpleAddress>;
  contact?: Maybe<Contact>;
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type ContactPersonInput = {
  address?: InputMaybe<SimpleAddressInput>;
  contact?: InputMaybe<ContactInput>;
  name: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Filter to restrict a query
 *
 * Usage:
 * - Specify customer, if you want to filter for a customer.
 * - Specify both customer and organization, if you want to filter for an organization.
 * - Specify all of the three fields, if you want to filter for an institution.
 *
 * This API might change soon.
 */
export type ContextFilter = {
  customer?: InputMaybe<Scalars['ObjectId']['input']>;
  institution?: InputMaybe<Scalars['ObjectId']['input']>;
  organization?: InputMaybe<Scalars['ObjectId']['input']>;
};

/**
 * Oneof input object in GraphQL
 * Oneof input objects requires have exactly one field
 */
export type ContextFilterInput = {
  customerFilter?: InputMaybe<CustomerFilter>;
  institutionFilter?: InputMaybe<InstitutionFilter>;
  organizationFilter?: InputMaybe<OrganizationFilter>;
};

export type Contract = {
  __typename?: 'Contract';
  childId: Scalars['ObjectId']['output'];
  /** Find the most recent creation date */
  contractCreationDate?: Maybe<Scalars['NaiveDate']['output']>;
  /** Find the most recent signing date */
  contractSigningDate?: Maybe<Scalars['NaiveDate']['output']>;
  created: Modification;
  endOfContract?: Maybe<Scalars['NaiveDate']['output']>;
  endOfMembership?: Maybe<Scalars['NaiveDate']['output']>;
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
  reasonOfLeave?: Maybe<Scalars['String']['output']>;
  registrationDate: Scalars['NaiveDate']['output'];
  remarks?: Maybe<Scalars['String']['output']>;
  sections: Array<ContractInstitutionSection>;
  startOfContract?: Maybe<Scalars['NaiveDate']['output']>;
  startOfMembership?: Maybe<Scalars['NaiveDate']['output']>;
  state: ContractState;
  /** Assume ordered by date and not empty */
  stateHistory: Array<HistoryEntry>;
};

export type ContractInstitutionSection = {
  __typename?: 'ContractInstitutionSection';
  contractId: Scalars['ObjectId']['output'];
  created: Modification;
  from?: Maybe<Scalars['NaiveDate']['output']>;
  id: Scalars['LongEntityId']['output'];
  institutionSection: Scalars['String']['output'];
  modified?: Maybe<Modification>;
  to?: Maybe<Scalars['NaiveDate']['output']>;
};

/** If used as an input argument in GraphQL, exactly one of the fields must be provided. */
export type ContractState = Contracted | Open | Queued | Rejected | Reserved | Terminated;

/** If used as an input argument in GraphQL, exactly one of the fields must be provided. */
export type ContractStateInput = {
  contracted?: InputMaybe<ContractedInput>;
  open?: InputMaybe<OpenInput>;
  queued?: InputMaybe<QueuedInput>;
  rejected?: InputMaybe<RejectedInput>;
  reserved?: InputMaybe<ReservedInput>;
  terminated?: InputMaybe<TerminatedInput>;
};

export type Contracted = {
  __typename?: 'Contracted';
  substate?: Maybe<Substate>;
};

export type ContractedInput = {
  substate?: InputMaybe<SubstateInput>;
};

export type CreateAddressCategoryInput = {
  addressId: Scalars['StrictEntityId']['input'];
  categoryId: Scalars['StrictEntityId']['input'];
};

export type CreateAddressInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountType?: InputMaybe<Scalars['String']['input']>;
  bankInstitution?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['Bic']['input']>;
  birthdate?: InputMaybe<Scalars['NaiveDate']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<ContactInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  doctor?: InputMaybe<Scalars['Boolean']['input']>;
  employer?: InputMaybe<Scalars['Boolean']['input']>;
  externalAddress?: InputMaybe<Scalars['Boolean']['input']>;
  externalPayer?: InputMaybe<Scalars['Boolean']['input']>;
  facility?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['Iban']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  otherAddress?: InputMaybe<Scalars['Boolean']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  specialService?: InputMaybe<Scalars['Boolean']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  volunteer?: InputMaybe<Scalars['Boolean']['input']>;
  volunteerDescription?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type CreateAnalysisInput = {
  date: Scalars['NaiveDate']['input'];
  mode: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateAppointmentChildInput = {
  appointmentId: Scalars['StrictEntityId']['input'];
  childId: Scalars['StrictEntityId']['input'];
};

export type CreateAppointmentEmployeeInput = {
  appointmentId: Scalars['StrictEntityId']['input'];
  employeeId: Scalars['StrictEntityId']['input'];
};

export type CreateAppointmentInput = {
  absence: Scalars['Boolean']['input'];
  from: Scalars['DateTime']['input'];
  fullDay: Scalars['Boolean']['input'];
  subject: Scalars['String']['input'];
  subtype?: InputMaybe<AppointmentType>;
  text?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  type: AppointmentType;
};

export type CreateBankInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountType?: InputMaybe<Scalars['String']['input']>;
  bankInstitution?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['Bic']['input']>;
  iban?: InputMaybe<Scalars['Iban']['input']>;
};

export type CreateBookingInput = {
  assistedIntegration?: Scalars['Boolean']['input'];
  childId: Scalars['StrictEntityId']['input'];
  communeId?: InputMaybe<Scalars['StrictEntityId']['input']>;
  from: Scalars['NaiveDate']['input'];
  lunch?: Scalars['Boolean']['input'];
  migration?: Scalars['Boolean']['input'];
  nurseryChild?: Scalars['Boolean']['input'];
  preCourse?: Scalars['Boolean']['input'];
  preSchooler?: InputMaybe<Enrollment>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  schoolData?: InputMaybe<CreateSchoolDataInput>;
  section?: InputMaybe<Scalars['String']['input']>;
  slotSplitting?: Scalars['Boolean']['input'];
  subsidyForParentalFee?: Scalars['Boolean']['input'];
  timeFactor: CreateTimeFactorDataInput;
  timeFactorManuallySet: Scalars['Boolean']['input'];
  times?: BookingTimesInput;
  to?: InputMaybe<Scalars['NaiveDate']['input']>;
  type?: BookingType;
};

export type CreateCategoryInput = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type CreateCharacteristicInput = {
  name: Scalars['String']['input'];
};

export type CreateChildAddressInput = {
  addressId: Scalars['StrictEntityId']['input'];
  childId: Scalars['StrictEntityId']['input'];
};

export type CreateChildCharacteristicInput = {
  characteristicId: Scalars['StrictEntityId']['input'];
  childId: Scalars['StrictEntityId']['input'];
};

export type CreateChildInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<SimpleAddressInput>;
  allergies?: InputMaybe<Array<Scalars['String']['input']>>;
  allowedToGoHomeAlone: Scalars['Boolean']['input'];
  birth: BirthInput;
  childId?: InputMaybe<Scalars['Int']['input']>;
  collectorNotes?: InputMaybe<Scalars['String']['input']>;
  collectors?: InputMaybe<Array<CreateCollectorInput>>;
  communeId?: InputMaybe<Scalars['ObjectId']['input']>;
  contact?: InputMaybe<ContactInput>;
  disability?: InputMaybe<CreateDisabilityInput>;
  emergencyMeasures?: InputMaybe<Scalars['String']['input']>;
  furtherSiblings?: InputMaybe<Scalars['String']['input']>;
  healthInsurance?: InputMaybe<Scalars['String']['input']>;
  movement: MovementInput;
  person: PersonInput;
  pictureId?: InputMaybe<Scalars['String']['input']>;
  previousInstitutions?: InputMaybe<Array<Scalars['String']['input']>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  siblings?: InputMaybe<Array<SiblingInput>>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  vaccination?: InputMaybe<CreateVaccinationInput>;
  visitingNotes?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCollectorInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCommuneInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<SimpleAddressInput>;
  comments?: InputMaybe<Scalars['String']['input']>;
  communeKey: Scalars['String']['input'];
  contactPerson?: InputMaybe<ContactPersonInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateContractInput = {
  childId: Scalars['ObjectId']['input'];
  endOfContract?: InputMaybe<Scalars['NaiveDate']['input']>;
  endOfMembership?: InputMaybe<Scalars['NaiveDate']['input']>;
  reasonOfLeave?: InputMaybe<Scalars['String']['input']>;
  registrationDate: Scalars['NaiveDate']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  startOfContract?: InputMaybe<Scalars['NaiveDate']['input']>;
  startOfMembership?: InputMaybe<Scalars['NaiveDate']['input']>;
};

export type CreateContractInstitutionSectionInput = {
  contractId: Scalars['ObjectId']['input'];
  from?: InputMaybe<Scalars['NaiveDate']['input']>;
  institutionSection: Scalars['String']['input'];
  to?: InputMaybe<Scalars['NaiveDate']['input']>;
};

export type CreateCustodianCategoryInput = {
  categoryId: Scalars['StrictEntityId']['input'];
  custodianId: Scalars['StrictEntityId']['input'];
};

export type CreateCustodianChildInput = {
  /** "personensorgeberechtigt" */
  allowedToCare?: Scalars['Boolean']['input'];
  /** "alleinerziehend" */
  alone?: Scalars['Boolean']['input'];
  /** "Alleinsorgeberechtigt" */
  aloneCare?: Scalars['Boolean']['input'];
  childId: Scalars['ObjectId']['input'];
  custodianId: Scalars['ObjectId']['input'];
  /** "Post" */
  post?: Scalars['Boolean']['input'];
  relation?: InputMaybe<Relation>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCustodianInput = {
  address?: SimpleAddressInput;
  bank?: CreateBankInput;
  birth?: BirthInput;
  contact?: ContactInput;
  employerId?: InputMaybe<Scalars['StrictEntityId']['input']>;
  honoraryPost?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  person: PersonInput;
  profession?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDisabilityInput = {
  certified: Scalars['Boolean']['input'];
  certifiedUntil?: InputMaybe<Scalars['NaiveDate']['input']>;
  intellectual: Scalars['Boolean']['input'];
  mental: Scalars['Boolean']['input'];
  physical: Scalars['Boolean']['input'];
};

export type CreateEmployeeCategoryInput = {
  categoryId: Scalars['StrictEntityId']['input'];
  employeeId: Scalars['StrictEntityId']['input'];
};

export type CreateEmployeeGroupInput = {
  employeeId: Scalars['ObjectId']['input'];
  from?: InputMaybe<Scalars['NaiveDate']['input']>;
  groupId: Scalars['ObjectId']['input'];
  role: GroupRole;
  to?: InputMaybe<Scalars['NaiveDate']['input']>;
};

export type CreateEmployeeInput = {
  address?: InputMaybe<SimpleAddressInput>;
  birth?: InputMaybe<BirthInput>;
  certificateOfGoodConduct?: InputMaybe<Scalars['NaiveDate']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<ContactInput>;
  education?: InputMaybe<Scalars['String']['input']>;
  educationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  initials?: InputMaybe<Scalars['String']['input']>;
  kitalinoExport?: InputMaybe<Scalars['Boolean']['input']>;
  movement: MovementInput;
  order?: InputMaybe<Scalars['Int']['input']>;
  person: PersonInput;
  personnelNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  statisticsEducation?: InputMaybe<Scalars['String']['input']>;
  statisticsPositionType?: InputMaybe<Scalars['String']['input']>;
  vaccination?: InputMaybe<CreateVaccinationInput>;
};

export type CreateEmployeeQualificationInput = {
  employeeId: Scalars['StrictEntityId']['input'];
  from: Scalars['NaiveDate']['input'];
  qualificationId: Scalars['StrictEntityId']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  resubmission?: InputMaybe<Scalars['NaiveDate']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['NaiveDate']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CreateEmployerCategoryInput = {
  categoryId: Scalars['StrictEntityId']['input'];
  employerId: Scalars['StrictEntityId']['input'];
};

export type CreateEmployerInput = {
  address: SimpleAddressInput;
  contact: ContactInput;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Only one value between `position` and `interruption` is allowed.
 * To uphold this invariant, the `CreateEmploymentInputValidator` must be used whereever `CreateEmploymentInput` is used as an input.
 */
export type CreateEmploymentInput = {
  additionalStaff?: InputMaybe<Scalars['Boolean']['input']>;
  beginMode?: InputMaybe<Scalars['String']['input']>;
  contractType?: InputMaybe<Scalars['String']['input']>;
  creditableWorkingHours?: InputMaybe<Scalars['Float']['input']>;
  employeeId: Scalars['ObjectId']['input'];
  endMode?: InputMaybe<Scalars['String']['input']>;
  institutionSectionWorkingHours?: InputMaybe<Array<SectionTimeInput>>;
  interruption?: InputMaybe<Interruption>;
  movement: MovementInput;
  notes?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Position>;
  statisticsAreaWorkingHours?: InputMaybe<Array<SectionTimeInput>>;
  times?: InputMaybe<TimesInput>;
  vacationDays?: InputMaybe<Scalars['Int']['input']>;
  workingHours?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateFeeTypeInput = {
  accounting: Scalars['String']['input'];
  description: Scalars['String']['input'];
};

export type CreateFoodTypeInput = {
  description: Scalars['String']['input'];
  key: Scalars['String']['input'];
};

export type CreateGroupChildInput = {
  childId: Scalars['StrictEntityId']['input'];
  groupId: Scalars['StrictEntityId']['input'];
  movement: MovementInput;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGroupInput = {
  actionGroup?: InputMaybe<Scalars['Boolean']['input']>;
  careTimes?: InputMaybe<TimesInput>;
  name: Scalars['String']['input'];
  serviceColor?: InputMaybe<Scalars['String']['input']>;
  synchronized?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateQualificationInput = {
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateRoleInput = {
  accessLevel: AccessLevel;
  name: Scalars['String']['input'];
  /** Role Id (Keycloak's group id) */
  templateRole?: InputMaybe<Scalars['RoleId']['input']>;
};

export type CreateSchoolDataInput = {
  className?: InputMaybe<Scalars['String']['input']>;
  schoolId: Scalars['StrictEntityId']['input'];
};

export type CreateSchoolInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<SimpleAddressInput>;
  contactPerson?: InputMaybe<ContactPersonInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Represents a service type
 *
 * Legacy name: "Dienstart"
 */
export type CreateServiceTypeInput = {
  bgcolor: Scalars['String']['input'];
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  times: TimeRangeInput;
  type: Scalars['String']['input'];
};

export type CreateTimeFactorDataInput = {
  category: BookingTimeCategory;
  daysPerWeek?: Scalars['Int']['input'];
  hoursPerWeek?: Scalars['Int']['input'];
};

export type CreateUserInput = {
  email: Scalars['String']['input'];
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  firstname: Scalars['String']['input'];
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastname: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  requiredActions?: InputMaybe<Array<RequiredUserAction>>;
  roleId: Scalars['RoleId']['input'];
  roomNumber?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type CreateVaccinationInput = {
  /**
   * This is date since contraindication and was called `ismmoeglab` in the
   * legacy application.
   */
  contraindicationEndDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  /** This confirms that all early diagnostics where performed. */
  earlyDiagnosticsCertified?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * This is the date of the first vaccination and was called `ism1dat` in the
   * legacy application.
   */
  firstConfirmationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  /**
   * This is the date of the last change of the vaccination status and was
   * called `ismstatdat` in the legacy application.
   */
  lastStatusUpdate?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   * This is the description of complications and was called `ismzweifel` in
   * the legacy application.
   */
  proofDoubts?: InputMaybe<Scalars['String']['input']>;
  /**
   * This is the date at which the vaccination was reported to the health
   * department and was called `ismmelddat` in the legacy application.
   */
  reportDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  /**
   * This is the date of the first vaccination and was called `ism2dat` in the
   * legacy application.
   */
  secondConfirmationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  /**
   * This is the vaccination status and was called `ismstatus` in the legacy
   * application.
   */
  status?: InputMaybe<VaccinationStatus>;
  /** This is the date of the tetanus vaccination. */
  tetanusVaccinationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  /**
   * This confirms the vaccination requirement is fulfilled or vaccination
   * consultation was taken.
   */
  vaccinationCertified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateYouthWelfareOfficeInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<SimpleAddressInput>;
  contactPerson?: InputMaybe<ContactPersonInput>;
  description: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type Created = {
  __typename?: 'Created';
  at: Scalars['NaiveDate']['output'];
};

export type CreatedInput = {
  at: Scalars['NaiveDate']['input'];
};

export type Custodian = {
  __typename?: 'Custodian';
  address: SimpleAddress;
  bank: Bank;
  birth: Birth;
  /** Get the associated [`Categories`](Category). */
  categories?: Maybe<Array<CustodianCategory>>;
  contact: Contact;
  created: Modification;
  /**
   * Get the associated [`Employer`].
   *
   * Requires rights to the `employer` resource.
   */
  employer?: Maybe<Employer>;
  employerId?: Maybe<Scalars['StrictEntityId']['output']>;
  honoraryPost?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  maritalStatus?: Maybe<MaritalStatus>;
  modified?: Maybe<Modification>;
  person: Person;
  profession?: Maybe<Scalars['String']['output']>;
};

export type CustodianCategory = {
  __typename?: 'CustodianCategory';
  /**
   * Get the [`Category`] object.
   *
   * Requires rights to the `category` resource.
   */
  category?: Maybe<Category>;
  categoryId: Scalars['ObjectId']['output'];
  created: Modification;
  custodianId: Scalars['ObjectId']['output'];
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
};

export type CustodianChild = {
  __typename?: 'CustodianChild';
  /** "personensorgeberechtigt" */
  allowedToCare: Scalars['Boolean']['output'];
  /** "alleinerziehend" */
  alone: Scalars['Boolean']['output'];
  /** "Alleinsorgeberechtigt" */
  aloneCare: Scalars['Boolean']['output'];
  /**
   * Get the associated [`Child`] object.
   *
   * Requires rights to the `child` resource.
   */
  child?: Maybe<Child>;
  childId: Scalars['ObjectId']['output'];
  created: Modification;
  /**
   * Get the associated [`Custodian`] object.
   *
   * Requires rights to the `custodian` resource.
   */
  custodian?: Maybe<Custodian>;
  custodianId: Scalars['ObjectId']['output'];
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
  /** "Post" */
  post: Scalars['Boolean']['output'];
  relation?: Maybe<Relation>;
  remarks?: Maybe<Scalars['String']['output']>;
};

export type Customer = {
  __typename?: 'Customer';
  cid?: Maybe<Scalars['ObjectId']['output']>;
  created: Modification;
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
  name: Scalars['String']['output'];
};

/**
 * CustomerFilter is used when filtering from the perspective of a customer using no filter or from the perspective of
 * an admin filtering for a specific customer
 */
export type CustomerFilter = {
  customer: Scalars['ObjectId']['input'];
};

export type CustomerLevel = {
  cid: Scalars['Cid']['input'];
};

export type CustomerUnitLevel = {
  cid: Scalars['Cid']['input'];
  uid: Scalars['Uid']['input'];
};

export type Disability = {
  __typename?: 'Disability';
  certified: Scalars['Boolean']['output'];
  certifiedUntil?: Maybe<Scalars['NaiveDate']['output']>;
  intellectual: Scalars['Boolean']['output'];
  mental: Scalars['Boolean']['output'];
  physical: Scalars['Boolean']['output'];
};

export type Employee = {
  __typename?: 'Employee';
  address?: Maybe<SimpleAddress>;
  /**
   * Get the associated [`Appointment`](Appointment).
   *
   * Requires rights to the `appointment` resource.
   */
  appointments?: Maybe<Array<Appointment>>;
  birth?: Maybe<Birth>;
  /** Get the associated [`Categories`](Category). */
  categories?: Maybe<Array<EmployeeCategory>>;
  certificateOfGoodConduct?: Maybe<Scalars['NaiveDate']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  contact?: Maybe<Contact>;
  education?: Maybe<Scalars['String']['output']>;
  educationDate?: Maybe<Scalars['NaiveDate']['output']>;
  employeeNumber?: Maybe<Scalars['String']['output']>;
  /**
   * Get the associated [`Employment`](Employment).
   *
   * Requires rights to the `employment` resource.
   */
  employments?: Maybe<Array<Employment>>;
  /** Get the associated [`Group`](EmployeeGroup). */
  groups?: Maybe<Array<EmployeeGroup>>;
  id: Scalars['LongEntityId']['output'];
  initials?: Maybe<Scalars['String']['output']>;
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  kitalinoExport?: Maybe<Scalars['Boolean']['output']>;
  movement: Movement;
  n: Scalars['Int']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  person: Person;
  personnelNumber?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  /** Get the associated [`Qualification`](EmployeeQualification)s. */
  qualifications?: Maybe<Array<EmployeeQualification>>;
  statisticsEducation?: Maybe<Scalars['String']['output']>;
  statisticsPositionType?: Maybe<Scalars['String']['output']>;
  vaccination?: Maybe<Vaccination>;
};

export type EmployeeAppointmentId = {
  appointmentId: Scalars['ObjectId']['input'];
  employeeId: Scalars['StrictEntityId']['input'];
};

export type EmployeeCategory = {
  __typename?: 'EmployeeCategory';
  /**
   * Get the [`Category`] object.
   *
   * Requires rights to the `category` resource.
   */
  category?: Maybe<Category>;
  categoryId: Scalars['ObjectId']['output'];
  created: Modification;
  employeeId: Scalars['ObjectId']['output'];
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
};

export type EmployeeGroup = {
  __typename?: 'EmployeeGroup';
  created: Modification;
  /**
   * Get the associated [`Employee`](Employee).
   *
   * Requires rights to the `employee` resource.
   */
  employee: Employee;
  employeeId: Scalars['ObjectId']['output'];
  from?: Maybe<Scalars['NaiveDate']['output']>;
  /**
   * Get the associated [`Group`](Group).
   *
   * Requires rights to the `group` resource.
   */
  group: Group;
  groupId: Scalars['ObjectId']['output'];
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
  role: GroupRole;
  to?: Maybe<Scalars['NaiveDate']['output']>;
};

export type EmployeeList = {
  __typename?: 'EmployeeList';
  items: Array<Employee>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type EmployeeQualification = {
  __typename?: 'EmployeeQualification';
  created: Modification;
  employeeId: Scalars['ObjectId']['output'];
  from: Scalars['NaiveDate']['output'];
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
  /**
   * Get the name of the associated [`Qualification`](Qualification).
   *
   * Requires rights to the `qualification` resource.
   */
  name?: Maybe<Scalars['String']['output']>;
  qualificationId: Scalars['ObjectId']['output'];
  remarks?: Maybe<Scalars['String']['output']>;
  resubmission?: Maybe<Scalars['NaiveDate']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['NaiveDate']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Employer = {
  __typename?: 'Employer';
  address: SimpleAddress;
  /** Get the associated [`Categories`](Category). */
  categories?: Maybe<Array<EmployerCategory>>;
  contact: Contact;
  created: Modification;
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  lastname: Scalars['String']['output'];
  modified?: Maybe<Modification>;
  remarks?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Scalars['String']['output']>;
};

export type EmployerCategory = {
  __typename?: 'EmployerCategory';
  /**
   * Get the [`Category`] object.
   *
   * Requires rights to the `category` resource.
   */
  category?: Maybe<Category>;
  categoryId: Scalars['ObjectId']['output'];
  created: Modification;
  employerId: Scalars['ObjectId']['output'];
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
};

export type EmployerList = {
  __typename?: 'EmployerList';
  items: Array<Employer>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Employment = {
  __typename?: 'Employment';
  additionalStaff?: Maybe<Scalars['Boolean']['output']>;
  beginMode?: Maybe<Scalars['String']['output']>;
  contractType?: Maybe<Scalars['String']['output']>;
  creditableWorkingHours?: Maybe<Scalars['Float']['output']>;
  /**
   * Get the associated [`Employee`]
   *
   * Requires rights to the `employee` resource.
   */
  employee?: Maybe<Employee>;
  endMode?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  institutionSectionWorkingHours?: Maybe<Array<SectionTime>>;
  interruption?: Maybe<Interruption>;
  movement: Movement;
  n: Scalars['Int']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  position?: Maybe<Position>;
  statisticsAreaWorkingHours?: Maybe<Array<SectionTime>>;
  times?: Maybe<Times>;
  vacationDays?: Maybe<Scalars['Int']['output']>;
  workingHours?: Maybe<Scalars['Float']['output']>;
};

export type EmploymentList = {
  __typename?: 'EmploymentList';
  items: Array<Employment>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum Enrollment {
  /** "zurückgestellt" */
  Postponed = 'POSTPONED',
  /** "vorzeitig" */
  Prematurely = 'PREMATURELY',
  /** "regulär" */
  Regular = 'REGULAR'
}

export type FeeType = {
  __typename?: 'FeeType';
  accounting: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  n: Scalars['Int']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
};

export type FeeTypeList = {
  __typename?: 'FeeTypeList';
  items: Array<FeeType>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** An owned version of an RcObject, used to send to the frontend to add data such as `id`, `organization`, `institution` and `linked_to`. */
export type FoodType = {
  __typename?: 'FoodType';
  description: Scalars['String']['output'];
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  key: Scalars['String']['output'];
  /** Get [Institutions](RestrictedInstitution) this object is linked to. */
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
};

export type FoodTypeList = {
  __typename?: 'FoodTypeList';
  items: Array<FoodType>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** The GraphQL version for [`FunctionalRole`] with named fields */
export type FunctionalPermission = {
  __typename?: 'FunctionalPermission';
  functional: FunctionalRole;
};

/** The GraphQL version for [`FunctionalRole`] with named fields */
export type FunctionalPermissionInput = {
  functional: FunctionalRole;
};

/** A model for the functional roles */
export enum FunctionalRole {
  ChildAllowSensitiveData = 'child_allow_sensitive_data'
}

export type Group = {
  __typename?: 'Group';
  actionGroup?: Maybe<Scalars['Boolean']['output']>;
  careTimes?: Maybe<Times>;
  /** Get the associated [`Child`](GroupChild)ren. */
  children: Array<GroupChild>;
  created: Modification;
  /**
   * Get the associated [`Employee`](Employee).
   *
   * Requires rights to the `employee` resource.
   */
  employees: Array<Employee>;
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  /** Get the associated [`Management`](EmployeeGroup). */
  managements?: Maybe<Array<EmployeeGroup>>;
  modified?: Maybe<Modification>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  serviceColor?: Maybe<Scalars['String']['output']>;
  synchronized?: Maybe<Scalars['Boolean']['output']>;
};

export type GroupChild = {
  __typename?: 'GroupChild';
  /**
   * Get the associated [`Child`](Child).
   *
   * Requires rights to the `child` resource.
   */
  child: Child;
  /**
   * Get the associated [`Group`](Group).
   *
   * Requires rights to the `group` resource.
   */
  group: Group;
  id: Scalars['LongEntityId']['output'];
  movement: Movement;
  notes?: Maybe<Scalars['String']['output']>;
};

export type GroupList = {
  __typename?: 'GroupList';
  items: Array<Group>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export enum GroupRole {
  /** Leiter */
  Manager = 'manager',
  /** Betreuer */
  Supervisor = 'supervisor'
}

export type HistoryEntry = {
  __typename?: 'HistoryEntry';
  at: Scalars['NaiveDate']['output'];
  state: ContractState;
};

export type Holiday = {
  __typename?: 'Holiday';
  date: Scalars['NaiveDate']['output'];
  key: Scalars['String']['output'];
  kind: HolidayKind;
  name: Scalars['String']['output'];
};

export enum HolidayKind {
  Bavaria = 'BAVARIA',
  Local = 'LOCAL',
  National = 'NATIONAL',
  Special = 'SPECIAL'
}

export type Institution = {
  __typename?: 'Institution';
  created: Modification;
  customer?: Maybe<Customer>;
  id: Scalars['LongEntityId']['output'];
  iid?: Maybe<Scalars['ObjectId']['output']>;
  modified?: Maybe<Modification>;
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
};

/**
 * InstitutionFilter is used when filtering from the perspective of a institution using no filter, from the perspective
 * of an admin, a customer or an organization filtering for a specific institution
 */
export type InstitutionFilter = {
  customer: Scalars['ObjectId']['input'];
  institution: Scalars['ObjectId']['input'];
  organization: Scalars['ObjectId']['input'];
};

export type InstitutionLevel = {
  cid: Scalars['Cid']['input'];
  iid: Scalars['Iid']['input'];
  oid: Scalars['Oid']['input'];
};

export type InstitutionSection = {
  __typename?: 'InstitutionSection';
  contactPerson?: Maybe<ContactPerson>;
  costCenter?: Maybe<Scalars['String']['output']>;
  hexColor?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  shortname?: Maybe<Scalars['String']['output']>;
  type: InstitutionType;
};

export enum InstitutionType {
  ErweiterteMiniKita = 'ERWEITERTE_MINI_KITA',
  HausFuerKinder = 'HAUS_FUER_KINDER',
  Hort = 'HORT',
  Kindergarten = 'KINDERGARTEN',
  Kinderkrippe = 'KINDERKRIPPE',
  KooperativeGanztagesbetreuung = 'KOOPERATIVE_GANZTAGESBETREUUNG',
  MiniKita = 'MINI_KITA',
  Mittagsbetreuung = 'MITTAGSBETREUUNG',
  NetzFuerKinder = 'NETZ_FUER_KINDER',
  Vorschulkindergarten = 'VORSCHULKINDERGARTEN'
}

export enum IntegrativePlace {
  One = 'ONE',
  Three = 'THREE',
  Two = 'TWO',
  TwoPointThree = 'TWO_POINT_THREE'
}

export enum Interruption {
  /** "Krankheit ( > 4 Wochen)" */
  I1 = 'I1',
  /** "Elternzeit" */
  I2 = 'I2',
  /** "Sabbatjahr" */
  I3 = 'I3',
  /** "Sonderurlaub" */
  I4 = 'I4',
  /** "Freistellungsphase Altersteilzeit" */
  I5 = 'I5'
}

export type ListFilter = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export enum MaritalStatus {
  /** "getrennt lebend" */
  Gl = 'GL',
  /** "geschieden" */
  Gs = 'GS',
  /** "ledig" */
  Ld = 'LD',
  /** "eingetragene Lebenspartnerschaft" */
  Lp = 'LP',
  /** "nicht verheiratet" */
  Nv = 'NV',
  /** "verheiratet" */
  Vh = 'VH',
  /** "verwitwet" */
  Vw = 'VW',
  /** "wiederverheiratet / geschieden" */
  Wg = 'WG',
  /** "wiederverheiratet / verwitwet" */
  Ww = 'WW'
}

export type MemberId = {
  __typename?: 'MemberId';
  cid: Scalars['ObjectId']['output'];
  iid: Scalars['ObjectId']['output'];
  oid: Scalars['ObjectId']['output'];
};

export type Modification = {
  __typename?: 'Modification';
  at: Scalars['DateTime']['output'];
};

export type Movement = {
  __typename?: 'Movement';
  entry: Scalars['NaiveDate']['output'];
  exit?: Maybe<Scalars['NaiveDate']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
};

export type MovementInput = {
  entry: Scalars['NaiveDate']['input'];
  exit?: InputMaybe<Scalars['NaiveDate']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type MutationRoot = {
  __typename?: 'MutationRoot';
  createAddress: Address;
  /** Create an assignment between an address and a category. */
  createAddressCategoryAssignment: AddressCategory;
  createAnalysis: Analysis;
  createAppointment: Appointment;
  /** Create an assignment between an appointment and a child. */
  createAppointmentChildAssignment: AppointmentChild;
  /** Create an assignment between an appointment and an employee. */
  createAppointmentEmployeeAssignment: AppointmentEmployee;
  createBooking: Booking;
  createCategory: Category;
  createCharacteristic: Characteristic;
  createChild: Child;
  /** Create an assignment between a child and an address. */
  createChildAddressAssignment: ChildAddress;
  /** Create an assignment between a child and a characteristic. */
  createChildCharacteristicAssignment: ChildCharacteristic;
  createCommune: Commune;
  createContract: Contract;
  /**
   * Create an assignment between a contract and an institution section.
   *
   * Requires the `contract:update` right.
   */
  createContractInstitutionSectionAssignment: ContractInstitutionSection;
  /** Requires the `custodian:create` right */
  createCustodian: Custodian;
  /** Create an assignment between a custodian and a category. */
  createCustodianCategoryAssignment: CustodianCategory;
  /** Create an assignment between a custodian and a child. */
  createCustodianChildAssignment: CustodianChild;
  createCustomer: ProjectCustomer;
  createEmployee: Employee;
  /** Create an assignment between an employee and a category. */
  createEmployeeCategoryAssignment: EmployeeCategory;
  createEmployeeGroupAssignment: EmployeeGroup;
  /** Create an assignment between an employee and a qualification. */
  createEmployeeQualificationAssignment: EmployeeQualification;
  /** Requires the `employer:create` right */
  createEmployer: Employer;
  /**
   * Create an assignment between an employer and a category.
   *
   * Requires both `custodian:update` and `category:update` rights
   */
  createEmployerCategoryAssignment: EmployerCategory;
  createEmployment: Employment;
  createFeeType: FeeType;
  createFoodType: FoodType;
  createGroup: Group;
  createGroupChildAssignment: GroupChild;
  createInstitution: ProjectInstitution;
  createOrganization: ProjectOrganization;
  createOrganizationUnit: ProjectOrganizationUnit;
  createQualification: Qualification;
  createRole: Role;
  createSchool: School;
  createServiceType: ServiceType;
  createTablePreset: TablePreset;
  createUser: User;
  createYouthWelfareOffice: YouthWelfareOffice;
  linkAddresses: Scalars['Int']['output'];
  linkCategories: Scalars['Int']['output'];
  linkCommunes: Scalars['Int']['output'];
  linkFoodTypes: Scalars['Int']['output'];
  linkQualifications: Scalars['Int']['output'];
  linkSchools: Scalars['Int']['output'];
  linkServiceTypes: Scalars['Int']['output'];
  linkYouthWelfareOffices: Scalars['Int']['output'];
  /** Remove assignments between an address and a category. */
  removeAddressCategoryAssignments: Scalars['Int']['output'];
  removeAddresses: Scalars['Int']['output'];
  removeAnalyses: Scalars['Int']['output'];
  /** Remove assignments between an appointment and a child. */
  removeAppointmentChildAssignments: Scalars['Int']['output'];
  /** Remove assignments between an appointment and an employee. */
  removeAppointmentEmployeeAssignments: Scalars['Int']['output'];
  removeAppointments: Scalars['Int']['output'];
  removeBookings: Scalars['Int']['output'];
  removeCategories: Scalars['Int']['output'];
  removeCharacteristics: Scalars['Int']['output'];
  /** Remove assignments between a child and an address. */
  removeChildAddressAssignments: Scalars['Int']['output'];
  /** Remove assignments between a child and a characteristic. */
  removeChildCharacteristicAssignments: Scalars['Int']['output'];
  removeChildren: Scalars['Int']['output'];
  removeCommunes: Scalars['Int']['output'];
  /**
   * Remove assignments between contracts and institution sections.
   *
   * Requires the `contract:update` right.
   */
  removeContractInstitutionSectionAssignments: Scalars['Int']['output'];
  removeContracts: Scalars['Int']['output'];
  /** Remove assignments between a custodian and a category. */
  removeCustodianCategoryAssignments: Scalars['Int']['output'];
  /** Remove an assignment between a custodian and a child. */
  removeCustodianChildAssignment: Scalars['Int']['output'];
  /** Requires the `custodian:delete` right */
  removeCustodians: Scalars['Int']['output'];
  removeCustomers: Scalars['Int']['output'];
  /** Remove assignments between an employee and a category. */
  removeEmployeeCategoryAssignments: Scalars['Int']['output'];
  removeEmployeeGroupAssignments: Scalars['Int']['output'];
  /** Remove an assignment between an employee and a qualification. */
  removeEmployeeQualificationAssignments: Scalars['Int']['output'];
  removeEmployees: Scalars['Int']['output'];
  /**
   * Remove assignments between a employer and a category.
   *
   * Requires both `custodian:update` and `category:update` rights
   */
  removeEmployerCategoryAssignments: Scalars['Int']['output'];
  /** Requires the `employer:delete` right */
  removeEmployers: Scalars['Int']['output'];
  removeEmployments: Scalars['Int']['output'];
  removeFeeTypes: Scalars['Int']['output'];
  removeFoodTypes: Scalars['Int']['output'];
  removeGroupChildAssignments: Scalars['Int']['output'];
  removeGroups: Scalars['Int']['output'];
  removeInstitutions: Scalars['Int']['output'];
  removeOrganizationUnits: Scalars['Int']['output'];
  removeOrganizations: Scalars['Int']['output'];
  removeQualifications: Scalars['Int']['output'];
  removeRoles: Scalars['Int']['output'];
  removeSchools: Scalars['Int']['output'];
  removeServiceTypes: Scalars['Int']['output'];
  removeTablePresets: Scalars['Int']['output'];
  removeUsers: Scalars['Int']['output'];
  removeYouthWelfareOffices: Scalars['Int']['output'];
  setDefaultPreset: Scalars['Boolean']['output'];
  unsetDefaultPreset: Scalars['Boolean']['output'];
  updateAddress?: Maybe<Address>;
  updateAnalysis: Analysis;
  updateAppointment: Appointment;
  updateBooking: Booking;
  updateCategory?: Maybe<Category>;
  updateCharacteristic: Characteristic;
  updateChild: Child;
  updateChildrenState: Scalars['Int']['output'];
  updateCommune?: Maybe<Commune>;
  updateContract: Contract;
  /**
   * Update an assignment between a contract and an institution sections.
   *
   * Requires the `contract:update` right.
   */
  updateContractInstitutionSectionAssignment: ContractInstitutionSection;
  /** Requires the `custodian:update` right */
  updateCustodian: Custodian;
  /** Update an assignment between a custodian and a child. */
  updateCustodianChildAssignment: CustodianChild;
  updateCustomer: ProjectCustomer;
  updateEmployee: Employee;
  updateEmployeeGroupAssignment: EmployeeGroup;
  /** Update an assignment between an employee and a qualification. */
  updateEmployeeQualificationAssignment: EmployeeQualification;
  /** Requires the `employer:update` right */
  updateEmployer: Employer;
  updateEmployment: Employment;
  updateFeeType: FeeType;
  updateFoodType?: Maybe<FoodType>;
  updateGroup: Group;
  updateGroupChildAssignment: GroupChild;
  updateInstitution: ProjectInstitution;
  updateOrganization: ProjectOrganization;
  updateOrganizationUnit: ProjectOrganizationUnit;
  updateQualification?: Maybe<Qualification>;
  updateRole: Role;
  updateSchool?: Maybe<School>;
  updateServiceType?: Maybe<ServiceType>;
  updateTablePreset: TablePreset;
  updateUser?: Maybe<User>;
  updateYouthWelfareOffice?: Maybe<YouthWelfareOffice>;
};


export type MutationRootCreateAddressArgs = {
  context: InstitutionFilter;
  input: CreateAddressInput;
};


export type MutationRootCreateAddressCategoryAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateAddressCategoryInput;
};


export type MutationRootCreateAnalysisArgs = {
  context: InstitutionFilter;
  input: CreateAnalysisInput;
};


export type MutationRootCreateAppointmentArgs = {
  context: InstitutionFilter;
  input: CreateAppointmentInput;
};


export type MutationRootCreateAppointmentChildAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateAppointmentChildInput;
};


export type MutationRootCreateAppointmentEmployeeAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateAppointmentEmployeeInput;
};


export type MutationRootCreateBookingArgs = {
  context: InstitutionFilter;
  input: CreateBookingInput;
};


export type MutationRootCreateCategoryArgs = {
  context: InstitutionFilter;
  input: CreateCategoryInput;
};


export type MutationRootCreateCharacteristicArgs = {
  context: InstitutionFilter;
  input: CreateCharacteristicInput;
};


export type MutationRootCreateChildArgs = {
  context: InstitutionFilter;
  input: CreateChildInput;
};


export type MutationRootCreateChildAddressAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateChildAddressInput;
};


export type MutationRootCreateChildCharacteristicAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateChildCharacteristicInput;
};


export type MutationRootCreateCommuneArgs = {
  context: InstitutionFilter;
  input: CreateCommuneInput;
};


export type MutationRootCreateContractArgs = {
  context: InstitutionFilter;
  input: CreateContractInput;
};


export type MutationRootCreateContractInstitutionSectionAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateContractInstitutionSectionInput;
};


export type MutationRootCreateCustodianArgs = {
  context: InstitutionFilter;
  input: CreateCustodianInput;
};


export type MutationRootCreateCustodianCategoryAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateCustodianCategoryInput;
};


export type MutationRootCreateCustodianChildAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateCustodianChildInput;
};


export type MutationRootCreateCustomerArgs = {
  name: Scalars['String']['input'];
  user?: InputMaybe<CreateUserInput>;
};


export type MutationRootCreateEmployeeArgs = {
  context: InstitutionFilter;
  input: CreateEmployeeInput;
};


export type MutationRootCreateEmployeeCategoryAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateEmployeeCategoryInput;
};


export type MutationRootCreateEmployeeGroupAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateEmployeeGroupInput;
};


export type MutationRootCreateEmployeeQualificationAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateEmployeeQualificationInput;
};


export type MutationRootCreateEmployerArgs = {
  context: InstitutionFilter;
  input: CreateEmployerInput;
};


export type MutationRootCreateEmployerCategoryAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateEmployerCategoryInput;
};


export type MutationRootCreateEmploymentArgs = {
  context: InstitutionFilter;
  input: CreateEmploymentInput;
};


export type MutationRootCreateFeeTypeArgs = {
  context: InstitutionFilter;
  input: CreateFeeTypeInput;
};


export type MutationRootCreateFoodTypeArgs = {
  context: InstitutionFilter;
  input: CreateFoodTypeInput;
};


export type MutationRootCreateGroupArgs = {
  context: InstitutionFilter;
  input: CreateGroupInput;
};


export type MutationRootCreateGroupChildAssignmentArgs = {
  context: InstitutionFilter;
  input: CreateGroupChildInput;
};


export type MutationRootCreateInstitutionArgs = {
  context: OrganizationFilter;
  name: Scalars['String']['input'];
  user?: InputMaybe<CreateUserInput>;
};


export type MutationRootCreateOrganizationArgs = {
  context: CustomerFilter;
  name: Scalars['String']['input'];
  user?: InputMaybe<CreateUserInput>;
};


export type MutationRootCreateOrganizationUnitArgs = {
  context: ContextFilterInput;
  members: Array<InstitutionFilter>;
  name: Scalars['String']['input'];
  user?: InputMaybe<CreateUserInput>;
};


export type MutationRootCreateQualificationArgs = {
  context: InstitutionFilter;
  input: CreateQualificationInput;
};


export type MutationRootCreateRoleArgs = {
  input: CreateRoleInput;
  org: CustomerFilter;
};


export type MutationRootCreateSchoolArgs = {
  context: InstitutionFilter;
  input: CreateSchoolInput;
};


export type MutationRootCreateServiceTypeArgs = {
  context: InstitutionFilter;
  input: CreateServiceTypeInput;
};


export type MutationRootCreateTablePresetArgs = {
  columns: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  table: Table;
};


export type MutationRootCreateUserArgs = {
  access: AccessInput;
  user: CreateUserInput;
};


export type MutationRootCreateYouthWelfareOfficeArgs = {
  context: InstitutionFilter;
  input: CreateYouthWelfareOfficeInput;
};


export type MutationRootLinkAddressesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkCategoriesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkCommunesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkFoodTypesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkQualificationsArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkSchoolsArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkServiceTypesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootLinkYouthWelfareOfficesArgs = {
  context: Array<InstitutionFilter>;
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveAddressCategoryAssignmentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveAddressesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveAnalysesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveAppointmentChildAssignmentsArgs = {
  ids: Array<AppointmentChildAssignment>;
};


export type MutationRootRemoveAppointmentEmployeeAssignmentsArgs = {
  ids: Array<AppointmentEmployeeAssignment>;
};


export type MutationRootRemoveAppointmentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveBookingsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveCategoriesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveCharacteristicsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveChildAddressAssignmentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveChildCharacteristicAssignmentsArgs = {
  ids: Array<ChildCharacteristicAssignment>;
};


export type MutationRootRemoveChildrenArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveCommunesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveContractInstitutionSectionAssignmentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveContractsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveCustodianCategoryAssignmentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveCustodianChildAssignmentArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveCustodiansArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveCustomersArgs = {
  ids: Array<StrictCustomerId>;
};


export type MutationRootRemoveEmployeeCategoryAssignmentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveEmployeeGroupAssignmentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveEmployeeQualificationAssignmentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveEmployeesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveEmployerCategoryAssignmentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveEmployersArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveEmploymentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveFeeTypesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveFoodTypesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveGroupChildAssignmentsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveGroupsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveInstitutionsArgs = {
  ids: Array<StrictInstitutionId>;
};


export type MutationRootRemoveOrganizationUnitsArgs = {
  ids: Array<StrictOrganizationUnitId>;
};


export type MutationRootRemoveOrganizationsArgs = {
  ids: Array<StrictOrganizationId>;
};


export type MutationRootRemoveQualificationsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveRolesArgs = {
  ids: Array<Scalars['RoleId']['input']>;
};


export type MutationRootRemoveSchoolsArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveServiceTypesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootRemoveTablePresetsArgs = {
  ids: Array<Scalars['ObjectId']['input']>;
};


export type MutationRootRemoveUsersArgs = {
  ids: Array<Scalars['LongEntityId']['input']>;
};


export type MutationRootRemoveYouthWelfareOfficesArgs = {
  ids: Array<Scalars['StrictEntityId']['input']>;
};


export type MutationRootSetDefaultPresetArgs = {
  id: Scalars['ObjectId']['input'];
  table: Table;
};


export type MutationRootUnsetDefaultPresetArgs = {
  table: Table;
};


export type MutationRootUpdateAddressArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateAddressInput;
};


export type MutationRootUpdateAnalysisArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateAnalysisInput;
};


export type MutationRootUpdateAppointmentArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateAppointmentInput;
};


export type MutationRootUpdateBookingArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateBookingInput;
};


export type MutationRootUpdateCategoryArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateCategoryInput;
};


export type MutationRootUpdateCharacteristicArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateCharacteristicInput;
};


export type MutationRootUpdateChildArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateChildInput;
};


export type MutationRootUpdateChildrenStateArgs = {
  inputs: Array<UpdateChildStateInput>;
};


export type MutationRootUpdateCommuneArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateCommuneInput;
};


export type MutationRootUpdateContractArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateContractInput;
};


export type MutationRootUpdateContractInstitutionSectionAssignmentArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateContractInstitutionSectionInput;
};


export type MutationRootUpdateCustodianArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateCustodianInput;
};


export type MutationRootUpdateCustodianChildAssignmentArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateCustodianChildInput;
};


export type MutationRootUpdateCustomerArgs = {
  context: CustomerFilter;
  input: UpdateProjectCustomerInput;
};


export type MutationRootUpdateEmployeeArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateEmployeeInput;
};


export type MutationRootUpdateEmployeeGroupAssignmentArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateEmployeeGroupInput;
};


export type MutationRootUpdateEmployeeQualificationAssignmentArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateEmployeeQualificationInput;
};


export type MutationRootUpdateEmployerArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateEmployerInput;
};


export type MutationRootUpdateEmploymentArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateEmploymentInput;
};


export type MutationRootUpdateFeeTypeArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateFeeTypeInput;
};


export type MutationRootUpdateFoodTypeArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateFoodTypeInput;
};


export type MutationRootUpdateGroupArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateGroupInput;
};


export type MutationRootUpdateGroupChildAssignmentArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateGroupChildInput;
};


export type MutationRootUpdateInstitutionArgs = {
  context: InstitutionFilter;
  input: UpdateProjectInstitutionInput;
};


export type MutationRootUpdateOrganizationArgs = {
  context: OrganizationFilter;
  input: UpdateProjectOrganizationInput;
};


export type MutationRootUpdateOrganizationUnitArgs = {
  context: StrictOrganizationUnitId;
  input: UpdateProjectOrganizationUnitInput;
};


export type MutationRootUpdateQualificationArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateQualificationInput;
};


export type MutationRootUpdateRoleArgs = {
  id: Scalars['RoleId']['input'];
  input: UpdateRoleInput;
};


export type MutationRootUpdateSchoolArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateSchoolInput;
};


export type MutationRootUpdateServiceTypeArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateServiceTypeInput;
};


export type MutationRootUpdateTablePresetArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['ObjectId']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRootUpdateUserArgs = {
  input: UpdateUserInput;
  userId: Scalars['UUID']['input'];
};


export type MutationRootUpdateYouthWelfareOfficeArgs = {
  id: Scalars['StrictEntityId']['input'];
  input: UpdateYouthWelfareOfficeInput;
};

export type Open = {
  __typename?: 'Open';
  /** This field is necessary, because GraphQL does not allow for unit types to be part of a union. */
  ignoreThis: Scalars['Int']['output'];
};

export type OpenInput = {
  /** This field is necessary, because GraphQL does not allow for unit types to be part of a union. */
  ignoreThis: Scalars['Int']['input'];
};

export type OpeningHours = {
  __typename?: 'OpeningHours';
  integrativePlace?: Maybe<IntegrativePlace>;
  placesNecessity?: Maybe<PlaceNecessity>;
  times?: Maybe<Times>;
};

export enum Operation {
  Create = 'create',
  Delete = 'delete',
  List = 'list',
  Report = 'report',
  Unlock = 'unlock',
  Update = 'update',
  View = 'view'
}

/** The GraphQL version for [`OperationalRole`] with named fields */
export type OperationalPermission = {
  __typename?: 'OperationalPermission';
  operation: Operation;
  resourceType: ResourceType;
};

/** The GraphQL version for [`OperationalRole`] with named fields */
export type OperationalPermissionInput = {
  operation: Operation;
  resourceType: ResourceType;
};

export type Organization = {
  __typename?: 'Organization';
  created: Modification;
  customer?: Maybe<Customer>;
  id: Scalars['LongEntityId']['output'];
  institutions?: Maybe<Array<Institution>>;
  modified?: Maybe<Modification>;
  name: Scalars['String']['output'];
  oid?: Maybe<Scalars['ObjectId']['output']>;
};

/**
 * OrganizationFilter is used when filtering from the perspective of a organization using no filter, from the
 * perspective of an admin or a customer filtering for a specific organization
 */
export type OrganizationFilter = {
  customer: Scalars['ObjectId']['input'];
  organization: Scalars['ObjectId']['input'];
};

export type OrganizationLevel = {
  cid: Scalars['Cid']['input'];
  oid: Scalars['Oid']['input'];
};

export type OrganizationUnit = {
  __typename?: 'OrganizationUnit';
  created: Modification;
  customer?: Maybe<Customer>;
  id: Scalars['LongEntityId']['output'];
  institutions?: Maybe<Array<Institution>>;
  members: Array<MemberId>;
  modified?: Maybe<Modification>;
  name: Scalars['String']['output'];
  oid?: Maybe<Scalars['ObjectId']['output']>;
  uid?: Maybe<Scalars['ObjectId']['output']>;
};

export type OrganizationUnitLevel = {
  cid: Scalars['Cid']['input'];
  oid: Scalars['Oid']['input'];
  uid: Scalars['Uid']['input'];
};

/** The GraphQL version for [`Role`] with actual container types */
export type Permission = FunctionalPermission | OperationalPermission;

/** The GraphQL version for [`Role`] with actual container types */
export type PermissionInput = {
  functional?: InputMaybe<FunctionalPermissionInput>;
  operational?: InputMaybe<OperationalPermissionInput>;
};

export type Person = {
  __typename?: 'Person';
  citizenships?: Maybe<Array<Scalars['String']['output']>>;
  denomination?: Maybe<Scalars['String']['output']>;
  firstname: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  isGermanFirstLanguage?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Array<Scalars['String']['output']>>;
  lastname: Scalars['String']['output'];
  middlename?: Maybe<Scalars['String']['output']>;
  salutation?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PersonInput = {
  citizenships?: InputMaybe<Array<Scalars['String']['input']>>;
  denomination?: InputMaybe<Scalars['String']['input']>;
  firstname: Scalars['String']['input'];
  gender: Scalars['String']['input'];
  isGermanFirstLanguage?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  lastname: Scalars['String']['input'];
  middlename?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Collects information about the places available in an institution.
 *
 * This is **not** representing a place!
 */
export type Place = {
  __typename?: 'Place';
  adolescent?: Maybe<Scalars['Int']['output']>;
  approved?: Maybe<Scalars['Int']['output']>;
  general?: Maybe<Scalars['Int']['output']>;
  integrative?: Maybe<Scalars['Int']['output']>;
  meal?: Maybe<Scalars['Int']['output']>;
  schoolChildren?: Maybe<Scalars['Int']['output']>;
  toddler?: Maybe<Scalars['Int']['output']>;
};

export enum PlaceNecessity {
  Three = 'THREE',
  TwoPointFive = 'TWO_POINT_FIVE'
}

export enum Position {
  /** "Sonstige Fachkraft" */
  A = 'A',
  /** "Berufspraktikant/in - Ergänzungskraft" */
  B = 'B',
  /** "Sonstige (nicht im Anstellungsschlüssel)" */
  D = 'D',
  /** "Erzieher/in - Fachkraft" */
  E = 'E',
  /** "Freigestellte Einrichtungsleitung (Leitungs- und Verwaltungsbonus)" */
  F = 'F',
  /** "Sonstige Ergänzungskraft" */
  G = 'G',
  /** "Heilpädagog/e/in - Fachkraft (nur bei integrativen Einrichtungen)" */
  H = 'H',
  /** "Sozialpädagogisches Einführungsjahr (SEJ)" */
  J = 'J',
  /** "Kinderpfleger/in - Ergänzungskraft" */
  K = 'K',
  /** "hauswirtschaftl.- technisches Personal" */
  N = 'N',
  /** "OptiPrax-Praktikant/in - Ergänzungskraft (anrechenbare Stunden angeben!)" */
  O = 'O',
  /** "Heilerziehungspfleger/in - Fachkraft (nur bei integrativen Einrichtungen)" */
  P = 'P',
  /** "Sozialpädagog/e/in - Fachkraft" */
  S = 'S',
  /** "Assistenzkraft (nicht im AS und FKQ)" */
  T = 'T',
  /** "Zusatzkraft für Einzelintegration (nicht im AS und FKQ, nur für Statistik) */
  U = 'U',
  /** "Erzieherpraktikant/in (SPS)" */
  V = 'V',
  /** "Verwaltungspersonal" */
  W = 'W',
  /** "Schwerpunktkraft (Sprache und Integration)" */
  Y = 'Y',
  /** "Zusatzkraft (integrative Einrichtungen)" */
  Z = 'Z'
}

export enum Priority {
  Eight = 'EIGHT',
  Five = 'FIVE',
  Four = 'FOUR',
  Nine = 'NINE',
  One = 'ONE',
  Seven = 'SEVEN',
  Six = 'SIX',
  Three = 'THREE',
  Two = 'TWO'
}

export type ProjectCustomer = {
  __typename?: 'ProjectCustomer';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  cid?: Maybe<Scalars['ObjectId']['output']>;
  contactPersons?: Maybe<Array<ContactPerson>>;
  created: Modification;
  customerNumber?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  modified?: Maybe<Modification>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type ProjectCustomerList = {
  __typename?: 'ProjectCustomerList';
  items: Array<ProjectCustomer>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ProjectInstitution = {
  __typename?: 'ProjectInstitution';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  banks?: Maybe<Array<Bank>>;
  /**
   * Get the associated [`Commune`](Commune).
   *
   * Requires rights to the `commune` resource.
   */
  commune?: Maybe<Commune>;
  communeId?: Maybe<Scalars['ObjectId']['output']>;
  contactPersons?: Maybe<Array<ContactPerson>>;
  created: Modification;
  customer?: Maybe<Customer>;
  email?: Maybe<Scalars['String']['output']>;
  hasAssumptionDay: Scalars['Boolean']['output'];
  hasAugsburgerPeaceFestival: Scalars['Boolean']['output'];
  id: Scalars['LongEntityId']['output'];
  iid?: Maybe<Scalars['ObjectId']['output']>;
  institutionNumber?: Maybe<Scalars['String']['output']>;
  internalInstitutionNumber?: Maybe<Scalars['String']['output']>;
  launchDate?: Maybe<Scalars['NaiveDate']['output']>;
  modified?: Maybe<Modification>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  openingHours?: Maybe<OpeningHours>;
  operatingPermitYear?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  phone?: Maybe<Scalars['String']['output']>;
  place?: Maybe<Place>;
  rid: ResourceId;
  sections?: Maybe<Array<InstitutionSection>>;
  sepaConfiguration?: Maybe<SepaConfiguration>;
  statistics?: Maybe<Statistics>;
  terminationDate?: Maybe<Scalars['NaiveDate']['output']>;
  type: InstitutionType;
  yearOfConstruction?: Maybe<Scalars['String']['output']>;
};

export type ProjectInstitutionList = {
  __typename?: 'ProjectInstitutionList';
  items: Array<ProjectInstitution>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ProjectOrganization = {
  __typename?: 'ProjectOrganization';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  contactPersons?: Maybe<Array<ContactPerson>>;
  created: Modification;
  customer?: Maybe<Customer>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  institutions?: Maybe<Array<Institution>>;
  modified?: Maybe<Modification>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  numberOfMembers?: Maybe<Scalars['Int']['output']>;
  oid?: Maybe<Scalars['ObjectId']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  rid: ResourceId;
};

export type ProjectOrganizationList = {
  __typename?: 'ProjectOrganizationList';
  items: Array<ProjectOrganization>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type ProjectOrganizationUnit = {
  __typename?: 'ProjectOrganizationUnit';
  created: Modification;
  customer?: Maybe<Customer>;
  id: Scalars['LongEntityId']['output'];
  institutions?: Maybe<Array<Institution>>;
  members: Array<MemberId>;
  modified?: Maybe<Modification>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  oid?: Maybe<Scalars['ObjectId']['output']>;
  rid: ResourceId;
  uid?: Maybe<Scalars['ObjectId']['output']>;
};

export type ProjectOrganizationUnitList = {
  __typename?: 'ProjectOrganizationUnitList';
  items: Array<ProjectOrganizationUnit>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** An owned version of an RcObject, used to send to the frontend to add data such as `id`, `organization`, `institution` and `linked_to`. */
export type Qualification = {
  __typename?: 'Qualification';
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  /** Get [Institutions](RestrictedInstitution) this object is linked to. */
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  type: Scalars['String']['output'];
};

export type QualificationList = {
  __typename?: 'QualificationList';
  items: Array<Qualification>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type QueryRoot = {
  __typename?: 'QueryRoot';
  addressById?: Maybe<Address>;
  addresses: AddressList;
  analyses: AnalysisList;
  analysisById?: Maybe<Analysis>;
  appointmentById?: Maybe<Appointment>;
  appointments: AppointmentList;
  /**
   * Get appointments filtered.
   *
   * Filter `from` and `to` are **incusive**.
   */
  appointmentsFiltered: Array<Appointment>;
  /**
   * Get all children with birthday in the next days.
   *
   * If no `day_offset` is provided, `30` days are assumed.
   */
  birthdays: ChildList;
  bookingById?: Maybe<Booking>;
  bookings: BookingList;
  categories: CategoryList;
  categoryById?: Maybe<Category>;
  characteristics: CharacteristicList;
  childById?: Maybe<Child>;
  children: ChildList;
  communeById?: Maybe<Commune>;
  communes: CommuneList;
  customerById?: Maybe<ProjectCustomer>;
  customerByName?: Maybe<ProjectCustomer>;
  customers: ProjectCustomerList;
  employeeById?: Maybe<Employee>;
  employees: EmployeeList;
  /** Requires the `employer:list` right. */
  employers: EmployerList;
  employmentById?: Maybe<Employment>;
  employments: EmploymentList;
  feeTypeById?: Maybe<FeeType>;
  feeTypes: FeeTypeList;
  foodTypeById?: Maybe<FoodType>;
  foodTypes: FoodTypeList;
  groupById?: Maybe<Group>;
  groups: GroupList;
  /**
   * Get holidays in range.
   *
   * Arguments `from` and `to` are **incusive**.
   */
  holidaysInRange: Array<Holiday>;
  institutionById?: Maybe<ProjectInstitution>;
  institutionByName?: Maybe<ProjectInstitution>;
  institutions: ProjectInstitutionList;
  organizationById?: Maybe<ProjectOrganization>;
  organizationByName?: Maybe<ProjectOrganization>;
  organizationUnitById?: Maybe<ProjectOrganizationUnit>;
  organizationUnits: ProjectOrganizationUnitList;
  organizations: ProjectOrganizationList;
  qualificationById?: Maybe<Qualification>;
  qualifications: QualificationList;
  roleById: Role;
  roles: RoleList;
  schoolById?: Maybe<School>;
  /** Get school holidays for Bavaria */
  schoolHolidays: Array<SchoolHoliday>;
  schools: SchoolList;
  serviceTypeById?: Maybe<ServiceType>;
  serviceTypes: ServiceTypeList;
  tablePresets: TablePresetList;
  userById?: Maybe<User>;
  users: UserList;
  youthWelfareOfficeById?: Maybe<YouthWelfareOffice>;
  youthWelfareOffices: YouthWelfareOfficeList;
};


export type QueryRootAddressByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootAddressesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootAnalysesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootAnalysisByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootAppointmentByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootAppointmentsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootAppointmentsFilteredArgs = {
  filter: AppointmentQueryFilter;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootBirthdaysArgs = {
  dayOffset?: InputMaybe<Scalars['Int']['input']>;
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootBookingByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootBookingsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootCategoriesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootCategoryByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootCharacteristicsArgs = {
  context?: InputMaybe<ContextFilter>;
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootChildByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootChildrenArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootCommuneByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootCommunesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootCustomerByIdArgs = {
  customerId: StrictCustomerId;
};


export type QueryRootCustomerByNameArgs = {
  name: Scalars['String']['input'];
};


export type QueryRootCustomersArgs = {
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootEmployeeByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootEmployeesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootEmployersArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootEmploymentByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootEmploymentsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootFeeTypeByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootFeeTypesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootFoodTypeByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootFoodTypesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootGroupByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootGroupsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootHolidaysInRangeArgs = {
  context: InstitutionFilter;
  from: Scalars['NaiveDate']['input'];
  to: Scalars['NaiveDate']['input'];
};


export type QueryRootInstitutionByIdArgs = {
  institutionId: StrictInstitutionId;
};


export type QueryRootInstitutionByNameArgs = {
  context: ContextFilter;
  name: Scalars['String']['input'];
};


export type QueryRootInstitutionsArgs = {
  context?: InputMaybe<ContextFilter>;
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootOrganizationByIdArgs = {
  organizationId: StrictOrganizationId;
};


export type QueryRootOrganizationByNameArgs = {
  context: ContextFilter;
  name: Scalars['String']['input'];
};


export type QueryRootOrganizationUnitByIdArgs = {
  context?: InputMaybe<ContextFilter>;
  organizationUnitId: StrictOrganizationUnitId;
};


export type QueryRootOrganizationUnitsArgs = {
  context?: InputMaybe<ContextFilter>;
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootOrganizationsArgs = {
  context?: InputMaybe<ContextFilter>;
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootQualificationByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootQualificationsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootRoleByIdArgs = {
  id: Scalars['RoleId']['input'];
};


export type QueryRootRolesArgs = {
  context?: InputMaybe<ContextFilter>;
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootSchoolByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootSchoolsArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootServiceTypeByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootServiceTypesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};


export type QueryRootTablePresetsArgs = {
  filter?: InputMaybe<ListFilter>;
  table?: InputMaybe<Table>;
};


export type QueryRootUserByIdArgs = {
  userId: Scalars['UUID']['input'];
};


export type QueryRootUsersArgs = {
  context?: InputMaybe<ContextFilter>;
  filter?: InputMaybe<ListFilter>;
};


export type QueryRootYouthWelfareOfficeByIdArgs = {
  id: Scalars['StrictEntityId']['input'];
};


export type QueryRootYouthWelfareOfficesArgs = {
  filter?: InputMaybe<ListFilter>;
  org?: InputMaybe<ContextFilter>;
};

export type Queued = {
  __typename?: 'Queued';
  priority: Priority;
};

export type QueuedInput = {
  priority: Priority;
};

export type Rejected = {
  __typename?: 'Rejected';
  at: Scalars['NaiveDate']['output'];
};

export type RejectedInput = {
  at: Scalars['NaiveDate']['input'];
};

export enum Relation {
  Father = 'father',
  Grandfather = 'grandfather',
  Grandmother = 'grandmother',
  Mother = 'mother',
  Neighbor = 'neighbor',
  Stepfather = 'stepfather',
  Stepmother = 'stepmother'
}

export enum RequiredUserAction {
  UpdatePassword = 'UPDATE_PASSWORD'
}

export type Reserved = {
  __typename?: 'Reserved';
  confirmed?: Maybe<Confirmed>;
};

export type ReservedInput = {
  confirmed?: InputMaybe<ConfirmedInput>;
};

export type ResourceId = {
  __typename?: 'ResourceId';
  id: Scalars['LongEntityId']['output'];
};

export enum ResourceType {
  Address = 'address',
  Analysis = 'analysis',
  Appointment = 'appointment',
  Booking = 'booking',
  Category = 'category',
  Characteristic = 'characteristic',
  Child = 'child',
  Commune = 'commune',
  Contract = 'contract',
  Custodian = 'custodian',
  Customer = 'customer',
  Employee = 'employee',
  Employer = 'employer',
  Employment = 'employment',
  FeeType = 'fee_type',
  FoodType = 'food_type',
  Group = 'group',
  Institution = 'institution',
  Organization = 'organization',
  OrganizationUnit = 'organization_unit',
  Payment = 'payment',
  Qualification = 'qualification',
  Role = 'role',
  School = 'school',
  SepaMandate = 'sepa_mandate',
  ServiceType = 'service_type',
  User = 'user',
  YouthWelfareOffice = 'youth_welfare_office'
}

export type RestrictedInstitution = {
  __typename?: 'RestrictedInstitution';
  id: Scalars['LongEntityId']['output'];
  name: Scalars['String']['output'];
};

export type Role = {
  __typename?: 'Role';
  accessLevel: AccessLevel;
  cid?: Maybe<Scalars['Cid']['output']>;
  id: Scalars['RoleId']['output'];
  n?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  permissions: Array<Permission>;
};

export type RoleList = {
  __typename?: 'RoleList';
  items: Array<Role>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** An owned version of an RcObject, used to send to the frontend to add data such as `id`, `organization`, `institution` and `linked_to`. */
export type School = {
  __typename?: 'School';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  contactPerson?: Maybe<ContactPerson>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  /** Get [Institutions](RestrictedInstitution) this object is linked to. */
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  phone?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
};

export type SchoolData = {
  __typename?: 'SchoolData';
  className?: Maybe<Scalars['String']['output']>;
  /**
   * Get the associated [`School`](School).
   *
   * Requires rights to the `School` resource.
   */
  school?: Maybe<School>;
  schoolId: Scalars['StrictEntityId']['output'];
};

export type SchoolHoliday = {
  __typename?: 'SchoolHoliday';
  endDate: Scalars['NaiveDate']['output'];
  id?: Maybe<Scalars['ObjectId']['output']>;
  name: Scalars['String']['output'];
  startDate: Scalars['NaiveDate']['output'];
  state: Scalars['String']['output'];
};

export type SchoolList = {
  __typename?: 'SchoolList';
  items: Array<School>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type SectionTime = {
  __typename?: 'SectionTime';
  name: Scalars['String']['output'];
  time: Scalars['Float']['output'];
};

export type SectionTimeInput = {
  name: Scalars['String']['input'];
  time: Scalars['Float']['input'];
};

export type SepaConfiguration = {
  __typename?: 'SepaConfiguration';
  paymentInstitution?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  sepaCreditorIdentificationNumber?: Maybe<Scalars['String']['output']>;
};

/** An owned version of an RcObject, used to send to the frontend to add data such as `id`, `organization`, `institution` and `linked_to`. */
export type ServiceType = {
  __typename?: 'ServiceType';
  bgcolor: Scalars['String']['output'];
  id: Scalars['LongEntityId']['output'];
  identifier: Scalars['String']['output'];
  /**
   * Get the associated [`Institution`](ProjectInstitution).
   *
   * Requires rights to the `institution` resource.
   */
  institution?: Maybe<ProjectInstitution>;
  /** Get [Institutions](RestrictedInstitution) this object is linked to. */
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  /**
   * Get the associated [`Organization`](ProjectOrganization).
   *
   * Requires rights to the `organization` resource.
   */
  organization?: Maybe<ProjectOrganization>;
  times: TimeRange;
  type: Scalars['String']['output'];
};

export type ServiceTypeList = {
  __typename?: 'ServiceTypeList';
  items: Array<ServiceType>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Sibling = {
  __typename?: 'Sibling';
  birthdate: Scalars['NaiveDate']['output'];
  firstname: Scalars['String']['output'];
};

export type SiblingInput = {
  birthdate: Scalars['NaiveDate']['input'];
  firstname: Scalars['String']['input'];
};

/** In the future, this will probably also contain the start date of the contract. */
export type Signed = {
  __typename?: 'Signed';
  at: Scalars['NaiveDate']['output'];
};

/** In the future, this will probably also contain the start date of the contract. */
export type SignedInput = {
  at: Scalars['NaiveDate']['input'];
};

export type SimpleAddress = {
  __typename?: 'SimpleAddress';
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type SimpleAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type Statistics = {
  __typename?: 'Statistics';
  close?: Maybe<Scalars['NaiveTime']['output']>;
  closeMidday?: Maybe<Scalars['Boolean']['output']>;
  companyEmployees?: Maybe<Scalars['Boolean']['output']>;
  legalForm?: Maybe<Scalars['String']['output']>;
  notFixedStructure?: Maybe<Scalars['Boolean']['output']>;
  open?: Maybe<Scalars['NaiveTime']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  parentInitiatives?: Maybe<Scalars['Boolean']['output']>;
  regionalNumber?: Maybe<Scalars['Int']['output']>;
  statisticalNumber?: Maybe<Scalars['Int']['output']>;
};

export type StrictCustomerId = {
  cid: Scalars['Cid']['input'];
};

export type StrictInstitutionId = {
  cid: Scalars['Cid']['input'];
  iid: Scalars['Iid']['input'];
  oid: Scalars['Oid']['input'];
};

export type StrictOrganizationId = {
  cid: Scalars['Cid']['input'];
  oid: Scalars['Oid']['input'];
};

export type StrictOrganizationUnitId = {
  cid: Scalars['Cid']['input'];
  oid?: InputMaybe<Scalars['Oid']['input']>;
  uid: Scalars['Uid']['input'];
};

/** If used as an input argument in GraphQL, exactly one of the fields must be provided. */
export type Substate = Created | Signed;

/** If used as an input argument in GraphQL, exactly one of the fields must be provided. */
export type SubstateInput = {
  created?: InputMaybe<CreatedInput>;
  signed?: InputMaybe<SignedInput>;
};

/** Every possible table in the application, that might need a preset */
export enum Table {
  Addresses = 'ADDRESSES',
  Appointments = 'APPOINTMENTS',
  Banks = 'BANKS',
  Categories = 'CATEGORIES',
  Characteristics = 'CHARACTERISTICS',
  Children = 'CHILDREN',
  Communes = 'COMMUNES',
  Customers = 'CUSTOMERS',
  Employees = 'EMPLOYEES',
  Employments = 'EMPLOYMENTS',
  FoodTypes = 'FOOD_TYPES',
  Groups = 'GROUPS',
  Institutions = 'INSTITUTIONS',
  Organizations = 'ORGANIZATIONS',
  OrganizationUnits = 'ORGANIZATION_UNITS',
  Qualifications = 'QUALIFICATIONS',
  Roles = 'ROLES',
  Schools = 'SCHOOLS',
  ServiceTypes = 'SERVICE_TYPES',
  Users = 'USERS',
  YouthWelfareOffices = 'YOUTH_WELFARE_OFFICES'
}

export type TablePreset = {
  __typename?: 'TablePreset';
  columns: Array<Scalars['String']['output']>;
  id: Scalars['ObjectId']['output'];
  isGlobal: Scalars['Boolean']['output'];
  isGlobalDefault: Scalars['Boolean']['output'];
  isUserDefault: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  table: Table;
};

export type TablePresetList = {
  __typename?: 'TablePresetList';
  items: Array<TablePreset>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Terminated = {
  __typename?: 'Terminated';
  at: Scalars['NaiveDate']['output'];
  reason: Scalars['String']['output'];
  terminationDate: Scalars['NaiveDate']['output'];
};

export type TerminatedInput = {
  at: Scalars['NaiveDate']['input'];
  reason: Scalars['String']['input'];
  terminationDate: Scalars['NaiveDate']['input'];
};

export type TimeFactorData = {
  __typename?: 'TimeFactorData';
  category: BookingTimeCategory;
  daysPerWeek: Scalars['Int']['output'];
  hoursPerWeek: Scalars['Int']['output'];
};

export type TimeRange = {
  __typename?: 'TimeRange';
  end?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
};

export type TimeRangeInput = {
  end?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

/** A naive time span with the requirement `from < to`. */
export type TimeSpan = {
  __typename?: 'TimeSpan';
  from: Scalars['NaiveTime']['output'];
  to: Scalars['NaiveTime']['output'];
};

/** A naive time span with the requirement `from < to`. */
export type TimeSpanInput = {
  from: Scalars['NaiveTime']['input'];
  to: Scalars['NaiveTime']['input'];
};

export type Times = {
  __typename?: 'Times';
  fr?: Maybe<Array<TimeRange>>;
  mo?: Maybe<Array<TimeRange>>;
  sa?: Maybe<Array<TimeRange>>;
  su?: Maybe<Array<TimeRange>>;
  th?: Maybe<Array<TimeRange>>;
  tu?: Maybe<Array<TimeRange>>;
  we?: Maybe<Array<TimeRange>>;
};

export type TimesInput = {
  fr?: InputMaybe<Array<TimeRangeInput>>;
  mo?: InputMaybe<Array<TimeRangeInput>>;
  sa?: InputMaybe<Array<TimeRangeInput>>;
  su?: InputMaybe<Array<TimeRangeInput>>;
  th?: InputMaybe<Array<TimeRangeInput>>;
  tu?: InputMaybe<Array<TimeRangeInput>>;
  we?: InputMaybe<Array<TimeRangeInput>>;
};

export type UpdateAddressInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountType?: InputMaybe<Scalars['String']['input']>;
  bankInstitution?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['Bic']['input']>;
  birthdate?: InputMaybe<Scalars['NaiveDate']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<UpdateContactInput>;
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  doctor?: InputMaybe<Scalars['Boolean']['input']>;
  employer?: InputMaybe<Scalars['Boolean']['input']>;
  externalAddress?: InputMaybe<Scalars['Boolean']['input']>;
  externalPayer?: InputMaybe<Scalars['Boolean']['input']>;
  facility?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['Iban']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  otherAddress?: InputMaybe<Scalars['Boolean']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  specialService?: InputMaybe<Scalars['Boolean']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  volunteer?: InputMaybe<Scalars['Boolean']['input']>;
  volunteerDescription?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAnalysisInput = {
  date?: InputMaybe<Scalars['NaiveDate']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAppointmentInput = {
  absence?: InputMaybe<Scalars['Boolean']['input']>;
  from?: InputMaybe<Scalars['DateTime']['input']>;
  fullDay?: InputMaybe<Scalars['Boolean']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  subtype?: InputMaybe<AppointmentType>;
  text?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<AppointmentType>;
};

export type UpdateBankInput = {
  accountHolder?: InputMaybe<Scalars['String']['input']>;
  accountType?: InputMaybe<Scalars['String']['input']>;
  bankInstitution?: InputMaybe<Scalars['String']['input']>;
  bic?: InputMaybe<Scalars['Bic']['input']>;
  iban?: InputMaybe<Scalars['Iban']['input']>;
};

export type UpdateBirthInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['NaiveDate']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  saintsDay?: InputMaybe<Scalars['NaiveDate']['input']>;
};

export type UpdateBookingInput = {
  assistedIntegration?: InputMaybe<Scalars['Boolean']['input']>;
  communeId?: InputMaybe<Scalars['StrictEntityId']['input']>;
  from?: InputMaybe<Scalars['NaiveDate']['input']>;
  lunch?: InputMaybe<Scalars['Boolean']['input']>;
  migration?: InputMaybe<Scalars['Boolean']['input']>;
  nurseryChild?: InputMaybe<Scalars['Boolean']['input']>;
  preCourse?: InputMaybe<Scalars['Boolean']['input']>;
  preSchooler?: InputMaybe<Enrollment>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  schoolData?: InputMaybe<UpdateSchoolDataInput>;
  section?: InputMaybe<Scalars['String']['input']>;
  slotSplitting?: InputMaybe<Scalars['Boolean']['input']>;
  subsidyForParentalFee?: InputMaybe<Scalars['Boolean']['input']>;
  timeFactor?: InputMaybe<UpdateTimeFactorDataInput>;
  timeFactorManuallySet?: InputMaybe<Scalars['Boolean']['input']>;
  times?: InputMaybe<UpdateBookingTimesInput>;
  to?: InputMaybe<Scalars['NaiveDate']['input']>;
  type?: InputMaybe<BookingType>;
};

export type UpdateBookingTimesInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  fr?: InputMaybe<AmPmInput>;
  mo?: InputMaybe<AmPmInput>;
  th?: InputMaybe<AmPmInput>;
  tu?: InputMaybe<AmPmInput>;
  we?: InputMaybe<AmPmInput>;
};

export type UpdateCategoryInput = {
  abbreviation?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCharacteristicInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChildInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  allergies?: InputMaybe<Array<Scalars['String']['input']>>;
  allowedToGoHomeAlone?: InputMaybe<Scalars['Boolean']['input']>;
  birth?: InputMaybe<UpdateBirthInput>;
  childId?: InputMaybe<Scalars['Int']['input']>;
  collectorNotes?: InputMaybe<Scalars['String']['input']>;
  collectors?: InputMaybe<Array<UpdateCollectorInput>>;
  communeId?: InputMaybe<Scalars['ObjectId']['input']>;
  contact?: InputMaybe<UpdateContactInput>;
  contractDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  contractReceived?: InputMaybe<Scalars['NaiveDate']['input']>;
  disability?: InputMaybe<UpdateDisabilityInput>;
  emergencyMeasures?: InputMaybe<Scalars['String']['input']>;
  furtherSiblings?: InputMaybe<Scalars['String']['input']>;
  healthInsurance?: InputMaybe<Scalars['String']['input']>;
  movement?: InputMaybe<UpdateMovementInput>;
  person?: InputMaybe<UpdatePersonInput>;
  pictureId?: InputMaybe<Scalars['String']['input']>;
  previousInstitutions?: InputMaybe<Array<Scalars['String']['input']>>;
  registration?: InputMaybe<UpdateRegistrationInput>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  siblings?: InputMaybe<Array<UpdateSiblingInput>>;
  symbol?: InputMaybe<Scalars['String']['input']>;
  vaccination?: InputMaybe<UpdateVaccinationInput>;
  visitingNotes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChildStateInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['StrictEntityId']['input'];
};

export type UpdateCollectorInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCommuneInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  comments?: InputMaybe<Scalars['String']['input']>;
  communeKey?: InputMaybe<Scalars['String']['input']>;
  contactPerson?: InputMaybe<UpdateContactPersonInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  letterSalutation?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneMobile?: InputMaybe<Scalars['String']['input']>;
  phoneWork?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactPersonInput = {
  address?: InputMaybe<UpdateSimpleAddressInput>;
  contact?: InputMaybe<UpdateContactInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContractInput = {
  endOfContract?: InputMaybe<Scalars['NaiveDate']['input']>;
  endOfMembership?: InputMaybe<Scalars['NaiveDate']['input']>;
  reasonOfLeave?: InputMaybe<Scalars['String']['input']>;
  registrationDate?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  startOfContract?: InputMaybe<Scalars['NaiveDate']['input']>;
  startOfMembership?: InputMaybe<Scalars['NaiveDate']['input']>;
  state?: InputMaybe<ContractStateInput>;
};

export type UpdateContractInstitutionSectionInput = {
  from?: InputMaybe<Scalars['NaiveDate']['input']>;
  institutionSection?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['NaiveDate']['input']>;
};

export type UpdateCustodianChildInput = {
  allowedToCare?: InputMaybe<Scalars['Boolean']['input']>;
  alone?: InputMaybe<Scalars['Boolean']['input']>;
  aloneCare?: InputMaybe<Scalars['Boolean']['input']>;
  post?: InputMaybe<Scalars['Boolean']['input']>;
  relation?: InputMaybe<Relation>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustodianInput = {
  address?: InputMaybe<UpdateSimpleAddressInput>;
  bank?: InputMaybe<UpdateBankInput>;
  birth?: InputMaybe<UpdateBirthInput>;
  contact?: InputMaybe<UpdateContactInput>;
  employerId?: InputMaybe<Scalars['StrictEntityId']['input']>;
  honoraryPost?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  person?: InputMaybe<UpdatePersonInput>;
  profession?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDisabilityInput = {
  certified?: InputMaybe<Scalars['Boolean']['input']>;
  certifiedUntil?: InputMaybe<Scalars['NaiveDate']['input']>;
  intellectual?: InputMaybe<Scalars['Boolean']['input']>;
  mental?: InputMaybe<Scalars['Boolean']['input']>;
  physical?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateEmployeeGroupInput = {
  employeeId?: InputMaybe<Scalars['ObjectId']['input']>;
  from?: InputMaybe<Scalars['NaiveDate']['input']>;
  groupId?: InputMaybe<Scalars['ObjectId']['input']>;
  role?: InputMaybe<GroupRole>;
  to?: InputMaybe<Scalars['NaiveDate']['input']>;
};

export type UpdateEmployeeInput = {
  address?: InputMaybe<UpdateSimpleAddressInput>;
  birth?: InputMaybe<UpdateBirthInput>;
  certificateOfGoodConduct?: InputMaybe<Scalars['NaiveDate']['input']>;
  comments?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<UpdateContactInput>;
  education?: InputMaybe<Scalars['String']['input']>;
  educationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  employeeNumber?: InputMaybe<Scalars['String']['input']>;
  initials?: InputMaybe<Scalars['String']['input']>;
  kitalinoExport?: InputMaybe<Scalars['Boolean']['input']>;
  movement?: InputMaybe<UpdateMovementInput>;
  order?: InputMaybe<Scalars['Int']['input']>;
  person?: InputMaybe<UpdatePersonInput>;
  personnelNumber?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  statisticsEducation?: InputMaybe<Scalars['String']['input']>;
  statisticsPositionType?: InputMaybe<Scalars['String']['input']>;
  vaccination?: InputMaybe<UpdateVaccinationInput>;
};

export type UpdateEmployeeQualificationInput = {
  employeeId?: InputMaybe<Scalars['ObjectId']['input']>;
  from?: InputMaybe<Scalars['NaiveDate']['input']>;
  qualificationId?: InputMaybe<Scalars['ObjectId']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  resubmission?: InputMaybe<Scalars['NaiveDate']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['NaiveDate']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateEmployerInput = {
  address?: InputMaybe<UpdateSimpleAddressInput>;
  contact?: InputMaybe<UpdateContactInput>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Only one value between "position" and "interruption" is allowed.
 * To uphold this invariant, the `UpdateEmploymentInputValidator` must be used whereever `UpdateEmployemntInput` is used as an input.
 */
export type UpdateEmploymentInput = {
  additionalStaff?: InputMaybe<Scalars['Boolean']['input']>;
  beginMode?: InputMaybe<Scalars['String']['input']>;
  contractType?: InputMaybe<Scalars['String']['input']>;
  creditableWorkingHours?: InputMaybe<Scalars['Float']['input']>;
  employeeId?: InputMaybe<Scalars['ObjectId']['input']>;
  endMode?: InputMaybe<Scalars['String']['input']>;
  institutionSectionWorkingHours?: InputMaybe<Array<SectionTimeInput>>;
  interruption?: InputMaybe<Interruption>;
  movement?: InputMaybe<UpdateMovementInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Position>;
  statisticsAreaWorkingHours?: InputMaybe<Array<SectionTimeInput>>;
  times?: InputMaybe<UpdateTimesInput>;
  vacationDays?: InputMaybe<Scalars['Int']['input']>;
  workingHours?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateFeeTypeInput = {
  accounting?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateFoodTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupChildInput = {
  movement?: InputMaybe<UpdateMovementInput>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGroupInput = {
  actionGroup?: InputMaybe<Scalars['Boolean']['input']>;
  careTimes?: InputMaybe<TimesInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  serviceColor?: InputMaybe<Scalars['String']['input']>;
  synchronized?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateInstitutionSectionInput = {
  contactPerson?: InputMaybe<UpdateContactPersonInput>;
  costCenter?: InputMaybe<Scalars['String']['input']>;
  hexColor?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  shortname?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<InstitutionType>;
};

export type UpdateMovementInput = {
  entry?: InputMaybe<Scalars['NaiveDate']['input']>;
  exit?: InputMaybe<Scalars['NaiveDate']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOpeningHoursInput = {
  integrativePlace?: InputMaybe<IntegrativePlace>;
  placesNecessity?: InputMaybe<PlaceNecessity>;
  times?: InputMaybe<TimesInput>;
};

export type UpdatePersonInput = {
  citizenships?: InputMaybe<Array<Scalars['String']['input']>>;
  denomination?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  isGermanFirstLanguage?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<Scalars['String']['input']>>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  middlename?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePlaceInput = {
  adolescent?: InputMaybe<Scalars['Int']['input']>;
  approved?: InputMaybe<Scalars['Int']['input']>;
  general?: InputMaybe<Scalars['Int']['input']>;
  integrative?: InputMaybe<Scalars['Int']['input']>;
  meal?: InputMaybe<Scalars['Int']['input']>;
  schoolChildren?: InputMaybe<Scalars['Int']['input']>;
  toddler?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateProjectCustomerInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  contactPersons?: InputMaybe<Array<UpdateContactPersonInput>>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectInstitutionInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  banks?: InputMaybe<Array<UpdateBankInput>>;
  communeId?: InputMaybe<Scalars['ObjectId']['input']>;
  contactPersons?: InputMaybe<Array<UpdateContactPersonInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  institutionNumber?: InputMaybe<Scalars['String']['input']>;
  internalInstitutionNumber?: InputMaybe<Scalars['String']['input']>;
  launchDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  openingHours?: InputMaybe<UpdateOpeningHoursInput>;
  operatingPermitYear?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<UpdatePlaceInput>;
  sections?: InputMaybe<Array<UpdateInstitutionSectionInput>>;
  sepaConfiguration?: InputMaybe<UpdateSepaConfigurationInput>;
  statistics?: InputMaybe<UpdateStatisticsInput>;
  terminationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  type?: InputMaybe<InstitutionType>;
  yearOfConstruction?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectOrganizationInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  contactPersons?: InputMaybe<Array<UpdateContactPersonInput>>;
  email?: InputMaybe<Scalars['String']['input']>;
  isUnit?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfMembers?: InputMaybe<Scalars['Int']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProjectOrganizationUnitInput = {
  members?: InputMaybe<Array<InstitutionFilter>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateQualificationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRegistrationInput = {
  date?: InputMaybe<Scalars['NaiveDate']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRoleInput = {
  accessLevel?: InputMaybe<AccessLevel>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<PermissionInput>>;
};

export type UpdateSchoolDataInput = {
  className?: InputMaybe<Scalars['String']['input']>;
  schoolId?: InputMaybe<Scalars['StrictEntityId']['input']>;
};

export type UpdateSchoolInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  contactPerson?: InputMaybe<UpdateContactPersonInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSepaConfigurationInput = {
  paymentInstitution?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  sepaCreditorIdentificationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateServiceTypeInput = {
  bgcolor?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  times?: InputMaybe<UpdateTimeRangeInput>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSiblingInput = {
  birthdate?: InputMaybe<Scalars['NaiveDate']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSimpleAddressInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStatisticsInput = {
  close?: InputMaybe<Scalars['NaiveTime']['input']>;
  closeMidday?: InputMaybe<Scalars['Boolean']['input']>;
  companyEmployees?: InputMaybe<Scalars['Boolean']['input']>;
  legalForm?: InputMaybe<Scalars['String']['input']>;
  notFixedStructure?: InputMaybe<Scalars['Boolean']['input']>;
  open?: InputMaybe<Scalars['NaiveTime']['input']>;
  organizationType?: InputMaybe<Scalars['String']['input']>;
  parentInitiatives?: InputMaybe<Scalars['Boolean']['input']>;
  regionalNumber?: InputMaybe<Scalars['Int']['input']>;
  statisticalNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTimeFactorDataInput = {
  category?: InputMaybe<BookingTimeCategory>;
  daysPerWeek?: InputMaybe<Scalars['Int']['input']>;
  hoursPerWeek?: InputMaybe<Scalars['Int']['input']>;
};

export type UpdateTimeRangeInput = {
  end?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTimesInput = {
  fr?: InputMaybe<Array<TimeRangeInput>>;
  mo?: InputMaybe<Array<TimeRangeInput>>;
  sa?: InputMaybe<Array<TimeRangeInput>>;
  su?: InputMaybe<Array<TimeRangeInput>>;
  th?: InputMaybe<Array<TimeRangeInput>>;
  tu?: InputMaybe<Array<TimeRangeInput>>;
  we?: InputMaybe<Array<TimeRangeInput>>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  roleId?: InputMaybe<Scalars['RoleId']['input']>;
  roomNumber?: InputMaybe<Scalars['String']['input']>;
  salutation?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateVaccinationInput = {
  contraindicationEndDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  earlyDiagnosticsCertified?: InputMaybe<Scalars['Boolean']['input']>;
  firstConfirmationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  proofDoubts?: InputMaybe<Scalars['String']['input']>;
  reportDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  secondConfirmationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  status?: InputMaybe<VaccinationStatus>;
  tetanusVaccinationDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  vaccinationCertified?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateYouthWelfareOfficeInput = {
  additionalAddress?: InputMaybe<Array<Scalars['String']['input']>>;
  address?: InputMaybe<UpdateSimpleAddressInput>;
  contactPerson?: InputMaybe<UpdateContactPersonInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  customer?: Maybe<Customer>;
  email: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  firstname: Scalars['String']['output'];
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<Institution>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastname: Scalars['String']['output'];
  n: Scalars['Int']['output'];
  organization?: Maybe<Organization>;
  organizationUnit?: Maybe<OrganizationUnit>;
  phone?: Maybe<Scalars['String']['output']>;
  roleId: Scalars['RoleId']['output'];
  salutation?: Maybe<Scalars['String']['output']>;
  userId: Scalars['UUID']['output'];
  username: Scalars['String']['output'];
};

export type UserList = {
  __typename?: 'UserList';
  items: Array<User>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Vaccination = {
  __typename?: 'Vaccination';
  /**
   * This is date since contraindication and was called `ismmoeglab` in the
   * legacy application.
   */
  contraindicationEndDate?: Maybe<Scalars['NaiveDate']['output']>;
  /** This confirms that all early diagnostics where performed. */
  earlyDiagnosticsCertified?: Maybe<Scalars['Boolean']['output']>;
  /**
   * This is the date of the first vaccination and was called `ism1dat` in the
   * legacy application.
   */
  firstConfirmationDate?: Maybe<Scalars['NaiveDate']['output']>;
  /**
   * This is the date of the last change of the vaccination status and was
   * called `ismstatdat` in the legacy application.
   */
  lastStatusUpdate?: Maybe<Scalars['DateTime']['output']>;
  /**
   * This is the description of complications and was called `ismzweifel` in
   * the legacy application.
   */
  proofDoubts?: Maybe<Scalars['String']['output']>;
  /**
   * This is the date at which the vaccination was reported to the health
   * department and was called `ismmelddat` in the legacy application.
   */
  reportDate?: Maybe<Scalars['NaiveDate']['output']>;
  /**
   * This is the date of the first vaccination and was called `ism2dat` in the
   * legacy application.
   */
  secondConfirmationDate?: Maybe<Scalars['NaiveDate']['output']>;
  /**
   * This is the vaccination status and was called `ismstatus` in the legacy
   * application.
   */
  status?: Maybe<VaccinationStatus>;
  /** This is the date of the tetanus vaccination. */
  tetanusVaccinationDate?: Maybe<Scalars['NaiveDate']['output']>;
  /**
   * This confirms the vaccination requirement is fulfilled or vaccination
   * consultation was taken.
   */
  vaccinationCertified?: Maybe<Scalars['Boolean']['output']>;
};

export enum VaccinationStatus {
  /** Vaccination fulfilled, person is below 12 months old */
  J0 = 'J0',
  /** Vaccination fulfilled, person below 12 months had first shot */
  J0_1 = 'J0_1',
  /** Vaccination fulfilled, person below 12 months had second shot */
  J0_2 = 'J0_2',
  /** Vaccination fulfilled, person below 12 has all required shots */
  J0_12 = 'J0_12',
  /** Vaccination fulfilled, person is below 24 months old */
  J1 = 'J1',
  /** Vaccination fulfilled, person below 24 months had second shot */
  J1_2 = 'J1_2',
  /** Vaccination fulfilled, person is has all required shots */
  J2 = 'J2',
  /** Vaccination fulfilled, person has attested immunity */
  J3 = 'J3',
  /** Vaccination fulfilled, person has attested contraindication */
  J4 = 'J4',
  /** Vaccination fulfilled, person with contraintication has all required shots */
  J4_1 = 'J4_1',
  /** Vaccination fulfilled, person has external proof */
  J5 = 'J5',
  /**
   * Vaccination incomplete, since no proof was provided until 2022-07-31;
   * Person is in the institution since before 2020-03-01
   */
  N1 = 'N1',
  /** Vaccination incomplete, because of doubts about the provided proof */
  N2 = 'N2',
  /** Vaccination incomplete */
  N3 = 'N3',
  /** Vaccination incomplete, since no proof was provided at follow-up inspection */
  N4 = 'N4',
  N5 = 'N5',
  /** Vaccination incomplete */
  N6 = 'N6',
  /**
   * Vaccination incomplete, since no proof was provided;
   * Person was accepted anyway
   */
  N7 = 'N7',
  /**
   * Vaccination incomplete, since no proof was provided;
   * Person was accepted due to authority exception (e.g. missing vaccine)
   */
  N8 = 'N8'
}

/** An owned version of an RcObject, used to send to the frontend to add data such as `id`, `organization`, `institution` and `linked_to`. */
export type YouthWelfareOffice = {
  __typename?: 'YouthWelfareOffice';
  additionalAddress?: Maybe<Array<Scalars['String']['output']>>;
  address?: Maybe<SimpleAddress>;
  contactPerson?: Maybe<ContactPerson>;
  description: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['LongEntityId']['output'];
  institution?: Maybe<ProjectInstitution>;
  linkedTo: Array<RestrictedInstitution>;
  n: Scalars['Int']['output'];
  organization?: Maybe<ProjectOrganization>;
  phone?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
};

export type YouthWelfareOfficeList = {
  __typename?: 'YouthWelfareOfficeList';
  items: Array<YouthWelfareOffice>;
  limit?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};
